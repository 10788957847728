import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from ".";

function CustomConfirmationDialog({
  onClose,
  open,
  dialogText,
  dialogTitle = "Confirmation",
  closeBtnText = "Ok",
  ...rest
}) {
  const { t } = useTranslation();

  return (
    <Dialog {...rest} open={open} onClose={onClose}>
      <DialogTitle>{t(dialogTitle)}</DialogTitle>
      <DialogContent>
        <DialogContentText>{t(dialogText)}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-test-id="dialog-close-button">
          {t(closeBtnText)}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(CustomConfirmationDialog);
