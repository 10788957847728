import React, {
  memo,
  useRef,
  useMemo,
  useCallback,
  useState,
  useEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { Page, DeleteDialog, useMediaQuery } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import useAuthorized, {
  KEYWORD_CREATE_PERMISSION,
  KEYWORD_DELETE_PERMISSION,
  KEYWORD_LIST_PERMISSION,
  KEYWORD_UPDATE_PERMISSION,
} from "../../../hooks/useAuthorized";
import Actions from "./Actions";
import KeywordBase from "./Base";
import { deleteKeyword, deleteKeywordById } from "../../../actions/keywords";
import { listKeywords } from "../../../actions/keywords";
import { setParams } from "../../../actions/params";
import { useHistory } from "react-router-dom";
import { useTableActions } from "../../../hooks/useTableActions";

const getExtraColumns = ({ t, onOpenDeleteDialog, canDelete, canUpdate }) => [
  {
    name: "actions",
    getter: (keyword) => (
      <Actions
        canDelete={canDelete}
        onDelete={onOpenDeleteDialog}
        keyword={keyword}
      />
    ),
    label: t("Actions"),
    sortable: false,
    width: 0.7,
    align: "center",
  },
];

const getShortCodes = ({
  sinchShortCodes = [],
  xmlShortCodes = [],
  jasminShortCodes = [],
}) =>
  [...sinchShortCodes, ...xmlShortCodes, ...jasminShortCodes]
    .map(Number)
    .sort();

function KeywordListsPage({ type }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const listsRef = useRef();
  const history = useHistory();

  const { smsConfig, emailConfig, vendorConfig } = useSelector(
    (state) => state.eonsConfig
  );

  const shortCodes = useMemo(
    () =>
      getShortCodes(
        smsConfig
          ? smsConfig
          : {
              sinchShortCodes: [],
              xmlShortCodes: [],
              jasminShortCodes: [],
            }
      ),
    [smsConfig]
  );

  const callerIds = useMemo(() => {
    return vendorConfig.callerId;
  }, [vendorConfig]);

  const keywordsList = useSelector((state) => state.keywords);
  const queryParams = useSelector((state) => state.params);

  const canDelete = useAuthorized(KEYWORD_DELETE_PERMISSION);
  const canCreate = useAuthorized(KEYWORD_CREATE_PERMISSION);
  const canList = useAuthorized(KEYWORD_LIST_PERMISSION);
  const canUpdate = useAuthorized(KEYWORD_UPDATE_PERMISSION);
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const [keywordToDelete, setKeywordToDelete] = useState();
  const {
    onItemsPerPageChange,
    onPageChange,
    onSearchChange,
  } = useTableActions();

  const authorizedToDoAnyAction = canDelete || canCreate;
  // const canView = useAuthorized;
  const canOnlyCreate = canCreate && !canList && !canDelete;

  const onOpenDeleteDialog = useCallback(
    (keyword) => setKeywordToDelete(keyword),
    [setKeywordToDelete]
  );

  const onCloseDeleteDialog = useCallback(() => setKeywordToDelete(null), [
    setKeywordToDelete,
  ]);

  const onDelete = useCallback(async () => {
    const { _id } = keywordToDelete;
    await dispatch(deleteKeywordById(_id));
    onCloseDeleteDialog();
    history.replace(`/keyword`);
  }, [dispatch, keywordToDelete, shortCodes, onCloseDeleteDialog, queryParams]);

  useEffect(() => {
    getKeywordList();
  }, [dispatch, shortCodes, queryParams, callerIds]);

  const getKeywordList = () => {
    dispatch(listKeywords({ shortCodes, params: queryParams }));
  };
  const extraColumns = useMemo(
    () =>
      getExtraColumns({
        t,
        onOpenDeleteDialog,
        authorizedToDoAnyAction,
        canDelete,
      }),
    [t, onOpenDeleteDialog, authorizedToDoAnyAction, canDelete]
  );
  return (
    <Page p={2} screenSized={!isSmallScreen}>
      <KeywordBase
        disableTable={canOnlyCreate}
        ref={listsRef}
        extraColumns={extraColumns}
        keywordsList={keywordsList ? _.reverse(keywordsList.list) : []}
        canCreate={canCreate}
        onItemsPerPageChange={onItemsPerPageChange}
        onSearchChange={onSearchChange}
        onPageChange={onPageChange}
        keywordCount={keywordsList ? keywordsList.count : 0}
        type={type}
      />
      <DeleteDialog
        open={Boolean(keywordToDelete)}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
        name={`the keyword ${keywordToDelete?.keyword}`}
        resource={"keyword"}
      ></DeleteDialog>
    </Page>
  );
}

export default memo(KeywordListsPage);
