import React, { useRef, useCallback, useEffect, memo } from "react";
import { useMediaQuery } from "@material-ui/core";
import DesktopTable from "./DesktopTable";
import MobileTable from "./MobileTable";

function SmartTable({
  data,
  totalCount,
  columns,
  itemsPerPage,
  onSortChange,
  order,
  orderBy,
  testId,
  ...rest
}) {
  const tableRef = useRef();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const ResponsiveTable = isSmallScreen ? MobileTable : DesktopTable;

  // scroll to the table's top when the data changes
  useEffect(() => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    } else {
      window.scrollTo({ top: 0 });
    }
  }, [tableRef, data]);

  // show a skeleton while there is no data from the API yet
  let loading = false;
  if (!data) {
    loading = true;
    data = Array(itemsPerPage).fill(null);
    totalCount = itemsPerPage;
  }
  // the headers could be dynamically fetched from the API
  // replace them with a 5 header placeholder skeleton table during the loading phase
  let columnsLoading = false;
  if (!columns) {
    columnsLoading = true;
    columns = Array(5).fill({});
  }

  // show a not found message when the API returned no data
  const notFound = !data.length;

  // toggle the order direction based on the column
  // if the user choses a new colum the order will start as ascending
  // after that the order swaps on every click
  const _onSortChange = useCallback(
    (columnName) =>
      onSortChange(columnName, orderBy === columnName ? -order : 1),
    [orderBy, order, onSortChange]
  );

  return (
    <ResponsiveTable
      {...rest}
      data={data}
      columns={columns}
      loading={loading}
      columnsLoading={columnsLoading}
      notFound={notFound}
      totalCount={totalCount}
      order={order}
      orderBy={orderBy}
      onSortChange={_onSortChange}
      itemsPerPage={itemsPerPage}
      tableRef={tableRef}
      testId={testId}
    />
  );
}

export default memo(SmartTable);
