import React, { useMemo, useCallback, useEffect, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { omit } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import RecipientList from "../../../RecipientLists/List/Base";
import { buildCampaign } from "../../../../actions/campaign";
import { setParams } from "../../../../actions/params";

import { createNewRecipientList } from "../../../../actions/newRecipientList";
import { Alert } from "../../../../components";
import Actions from "./Actions";
import ViewListDialog from "../../../../components/ViewListDialog";
import {
  getRecipientList,
  getRecipients,
} from "../../../../actions/recipientList";
import { RecipientsDataTable } from "../../../RecipientLists/Edit/Edit";

const useStyles = makeStyles((theme) => ({
  alert: {
    marginTop: theme.spacing(2),
  },
}));

const getParams = (
  { listPage, itemsPerPage, activeDivisionId, search, searchByFields },
  recipientListId
) => ({
  page: listPage || 0,
  itemsPerPage: itemsPerPage || 20,
  recipientListId,
  activeDivisionId,
  search,
  searchByFields,
});

function SelectCampaignRecipientList() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState(false);

  const { activeDivisionId, paid } = useSelector((state) => state.storage);
  const activeCompanyId = useSelector((state) => state.user.companyId);
  let recipientList = useSelector((state) => state.recipientList);

  const [recipientListId, setRecipientListId] = useState("");
  const queryParams = useSelector((state) => state.params);
  const params = useMemo(() => getParams(queryParams, recipientListId), [
    queryParams,
    recipientListId,
  ]);

  const campaign = useSelector((state) => state.campaign);
  const {
    lists: selectedLists = [],
    listDetails: selectedListDetails = [],
    errors = {},
  } = campaign || {};
  const onToggleList = useCallback(
    (recipientList, listId) =>
      dispatch(
        buildCampaign({
          lists:
            selectedLists.indexOf(listId) === -1
              ? [...selectedLists, listId]
              : selectedLists.filter((id) => id !== listId),
          listDetails: !selectedListDetails.find((item) => item._id === listId)
            ? [...selectedListDetails, recipientList]
            : selectedListDetails.filter((item) => item._id !== listId),
          errors: omit(errors, "lists"),
        })
      ),
    [dispatch, selectedLists, selectedListDetails, errors]
  );

  const onCreateNewList = (isCampaign) =>
    dispatch(createNewRecipientList({ redirect: isCampaign }));

  useEffect(() => {
    if (!selectedLists) {
      dispatch(buildCampaign({ lists: [], listDetails: [] }));
    }
  }, [selectedLists, selectedListDetails, dispatch]);

  const onOpenViewListModal = useCallback(
    (recipientListId) => {
      const getRecipientListParams = {
        activeDivisionId,
        activeCompanyId,
      };
      dispatch(getRecipientList(recipientListId, getRecipientListParams));
      dispatch(
        getRecipients(
          {
            recipientListId,
            page: params.page,
            itemsPerPage: params.itemsPerPage,
          },
          recipientListId
        )
      );
      setRecipientListId(recipientListId);
      setOpenDialog(true);
    },
    [dispatch]
  );

  // refetch the recipients whenever the params change
  useEffect(() => {
    if (recipientListId) {
      dispatch(getRecipients(params, recipientListId));
    }
  }, [dispatch, params, recipientListId]);

  const getActions = ({ t, selectedLists, onToggleList }) => [
    {
      name: "actions",
      getter: (list) => (
        <Actions
          recipientList={list}
          selectedLists={selectedLists}
          onToggleList={onToggleList}
          onOpenViewListModal={onOpenViewListModal}
        />
      ),
      label: t("Select"),
      sortable: false,
      width: 0.7,
      align: "center",
    },
  ];

  const actions = useMemo(
    () => getActions({ t, selectedLists, onToggleList }),
    [t, selectedLists, onToggleList]
  );

  return (
    <>
      <RecipientList
        isCampaign={true}
        data-test-id="campaign-recipient-list-table"
        extraColumns={actions}
        // onlyStatus={allowedStatus}
        onClickCreate={onCreateNewList}
      />
      {errors.lists && (
        <Alert
          severity="error"
          variant="filled"
          className={classes.alert}
          data-test-id="campaign-recipient-list-error-alert"
        >
          {t("You must select at least one recipient list")}
        </Alert>
      )}

      <ViewListDialog
        open={openDialog}
        onClose={() => {
          dispatch(setParams({ listPage: null }));
          setOpenDialog(false);
        }}
        resource={"List"}
      >
        <RecipientsDataTable
          isCampaignsPage={true}
          recipients={recipientList?.recipients}
          recipientHeaders={recipientList?.recipientHeaders}
          notFoundMessage={t("No recipients found")}
          page={params?.page || 0}
          hidePagination={false}
          itemsPerPage={params?.itemsPerPage || 20}
        />
      </ViewListDialog>
    </>
  );
}

export default memo(SelectCampaignRecipientList);
