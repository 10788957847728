/* eslint-disable no-console */

import { cleanUpHtmlStringForTemplate } from "./formatEscape";

const existsInvalidPlaceHolders = async (templateString) => {
  // Cheking for white space in between variable name
  const invalidPlaceholderRegex = /<%=?\s?([a-z]+(\s[a-z]+))+\s?%>/gi; 
  const invalidPlaceholders = [];
  let item;
  
  while ((item = invalidPlaceholderRegex.exec(templateString))) {
    if (item && Array.isArray(item)) {
      invalidPlaceholders.push(item[1].trim());
    }
  }
  return invalidPlaceholders;
};


export const validateTemplates = async (templates)=>{
    const {emailTemplate, smsTemplate} = templates;
    if(emailTemplate){
      const emailBodyTemplate = cleanUpHtmlStringForTemplate(emailTemplate.template);
      const emailSubjectTemplate = cleanUpHtmlStringForTemplate(emailTemplate.subjectEmail);
      const invalids = await existsInvalidPlaceHolders(emailBodyTemplate + ' ' + emailSubjectTemplate);
      if(invalids.length){          
        return {
          invalid:true, 
          message: "EMAIL template error! Please remove the space from your variable name '" + invalids[0]+"'"
        };
      }        
    }
    if(smsTemplate){
        const invalids = await existsInvalidPlaceHolders(smsTemplate.template);
        if(invalids.length){          
          return {
            invalid:true, 
            message: "SMS template error! Please remove the space from your variable name '" + invalids[0]+"'"
          };
        }        
    }
    return {invalid:false};

};