import React, { useCallback, useMemo, memo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import {
  ToggleButton,
  Button,
  ToggleButtonGroup,
  FormControl,
  FormHelperText,
  FormLabel,
} from "../../../../components";

const useStyles = makeStyles((theme) => ({
  label: {
    marginBottom: theme.spacing(1),
  },
  toggleButton: ({ hasError }) => ({
    flex: 1,
    maxWidth: 40,
    borderColor: hasError ? theme.palette.error.main : null,
  }),
  digitButton: ({ hasError }) => ({
    color: hasError ? theme.palette.error.main : null,
  }),
  clearButton: {
    color: `${theme.palette.secondary.main} !important`,
    border: `1px solid #e0e0e0`,
    "text-transform": "none",
    "font-size": "medium",
  },
}));

function getDigits({ size, withSymbols }) {
  let digits = Array(size + 1)
    .fill(null)
    .map((_, idx) => String(idx));

  if (withSymbols) {
    digits = [...digits, "#", "*"];
  }
  return digits;
}

function DigitSelector({
  value,
  name,
  onChange,
  size = 9,
  error,
  label,
  withSymbols,
  optional,
  readOnly,
}) {
  const hasError = Boolean(error);
  const classes = useStyles({ hasError });

  const [selected, setSelected] = useState(false);

  const _onClick = useCallback(() => {
    setSelected(!selected);
    return onChange({ target: { name } });
  }, [onChange, name]);

  const _onChange = useCallback(
    (_, newValue) => {
      setSelected(!selected);
      return newValue
        ? onChange({ target: { name, value: newValue } })
        : onChange({ target: { name } });
    },
    [onChange, name]
  );

  const digits = useMemo(() => getDigits({ size, withSymbols }), [
    size,
    withSymbols,
  ]);

  return (
    <FormControl error={hasError} fullWidth>
      <FormLabel component="legend" className={classes.label}>
        {label}
      </FormLabel>
      <ToggleButtonGroup
        size="small"
        value={String(value)}
        onChange={_onChange}
        exclusive
        fullWidth
      >
        {digits.map((digit) => (
          <ToggleButton
            key={digit}
            value={digit}
            data-test-id={`master-template-${label}-key-${digit}`}
            className={classNames(classes.toggleButton, classes.digitButton)}
            disabled={readOnly}
          >
            {digit}
          </ToggleButton>
        ))}
        {optional && (
          <Button
            className={classNames(classes.clearButton)}
            disabled={!value}
            onClick={_onClick}
          >
            Clear
          </Button>
        )}
      </ToggleButtonGroup>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

export default memo(DigitSelector);
