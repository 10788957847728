import React, { useMemo, Fragment, memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "date-fns";
import {
  Paper,
  Grid,
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Skeleton,
  FormControl,
  FormControlLabel,
  Checkbox,
} from "..";

const getCounts = (
  recipientLists,
  { emailTemplate, smsTemplate, voiceTemplate, ttyTemplate, customTemplate }
) =>
  recipientLists.reduce(
    (
      {
        emailCount = 0,
        smsCount = 0,
        voiceCount = 0,
        ttyCount = 0,
        customCount = 0,
      },
      {
        emailCount: listEmailCount = 0,
        smsCount: listSmsCount = 0,
        voiceCount: listVoiceCount = 0,
        ttyCount: listTTYCount = 0,
        customCount: listCustomCount = 0,
      }
    ) => ({
      emailCount: emailCount + (emailTemplate ? listEmailCount : 0),
      smsCount: smsCount + (smsTemplate ? listSmsCount : 0),
      voiceCount: voiceCount + (voiceTemplate ? listVoiceCount : 0),
      ttyCount: ttyCount + (ttyTemplate ? listTTYCount : 0),
      customCount: customCount + (customTemplate ? listCustomCount : 0),
    }),
    {}
  );

const useStyles = makeStyles((theme) => ({
  countsTable: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 2,
  },
  recipientsTitle: {
    marginBottom: theme.spacing(1),
  },
  infoHeader: {
    marginRight: theme.spacing(1),
  },
  infoSkeleton: {
    width: 140,
    display: "inline-block",
  },
}));

function Overview({
  name = "-",
  schedule = "-",
  recipientLists = [],
  masterTemplate = {},
  subTemplates = {},
  loading,
  bypassDndDns,
  bypassSuppression,
  isHighPriority,
  sendAsApproved,
  ...rest
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const counts = useMemo(() => getCounts(recipientLists, subTemplates), [
    recipientLists,
    subTemplates,
  ]);

  return (
    <Paper {...rest} p={2}>
      <Grid
        container
        spacing={2}
        alignItems="flex-start"
        justify="space-between"
      >
        <Grid item xs={12} sm={6}>
          <Grid container direction="column" spacing={1}>
            <Grid
              item
              xs={12}
              component={Typography}
              data-test-id="approverspage-overview-title"
            >
              <b className={classes.infoHeader}>{t("Title")}:</b>
              {loading ? (
                <Skeleton className={classes.infoSkeleton} />
              ) : (
                <span>{name}</span>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              component={Typography}
              data-test-id="approverspage-overview-recipient-lists"
            >
              <b className={classes.infoHeader}>{t("Recipient Lists")}:</b>
              {loading ? (
                <Skeleton className={classes.infoSkeleton} />
              ) : (
                recipientLists.map((recipientList, idx) => (
                  <Fragment key={recipientList._id}>
                    {recipientList.name}
                    {idx < recipientLists.length - 1 && ", "}
                  </Fragment>
                ))
              )}
            </Grid>
            <Grid
              item
              xs={12}
              component={Typography}
              data-test-id="approverspage-overview-template"
            >
              <b className={classes.infoHeader}>{t("Template")}:</b>
              {loading ? (
                <Skeleton className={classes.infoSkeleton} />
              ) : (
                masterTemplate.name
              )}
            </Grid>
            <Grid
              item
              xs={12}
              component={Typography}
              data-test-id="approverspage-overview-schedule"
            >
              <b className={classes.infoHeader}>{t("Scheduled Launch")}:</b>
              {loading ? (
                <Skeleton className={classes.infoSkeleton} />
              ) : (
                <span>{sendAsApproved? 'As soon as approved' : format(new Date(schedule), "MMMM do hh:mm aa")}</span>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.recipientsTitle}>
            <b>{t("Recipients count")}</b>
          </Typography>
          <TableContainer
            className={classes.countsTable}
            data-test-id="approverspage-overview-recipient-counts"
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">{t("Total")}</TableCell>
                  <TableCell align="center">{t("SMS")}</TableCell>
                  <TableCell align="center">{t("Email")}</TableCell>
                  <TableCell align="center">{t("Voice")}</TableCell>
                  <TableCell align="center">{t("TTY")}</TableCell>
                  <TableCell align="center">{t("Custom")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align="center">
                    {loading ? (
                      <Skeleton />
                    ) : (
                      counts.smsCount +
                      counts.emailCount +
                      counts.voiceCount +
                      counts.ttyCount +
                      counts.customCount
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {loading ? <Skeleton /> : counts.smsCount}
                  </TableCell>
                  <TableCell align="center">
                    {loading ? <Skeleton /> : counts.emailCount}
                  </TableCell>
                  <TableCell align="center">
                    {loading ? <Skeleton /> : counts.voiceCount}
                  </TableCell>
                  <TableCell align="center">
                    {loading ? <Skeleton /> : counts.ttyCount}
                  </TableCell>
                  <TableCell align="center">
                    {loading ? <Skeleton /> : counts.customCount}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name="bypassSuppression"
                  checked={bypassSuppression}
                />
              }
              label={t("Bypass Suppression")}
              data-test-id="campaign-bypass-suppression"
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={bypassDndDns}
                  name="bypassDndDns"
                />
              }
              label={t("Bypass DND")}
              data-test-id="campaign-bypass-dnd-dns"
            />
          </FormControl>
          <FormControl>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={isHighPriority}
                  name="isHighPriority"
                />
              }
              label={t("Is High Priority")}
              data-test-id="campaign-high-priority"
            />
          </FormControl>
        </Grid>
      </Grid>
    </Paper>
  );
}

export default memo(Overview);
