import React, { useCallback, useState, useMemo, memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector, batch } from "react-redux";
import classNames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { DropZone, Box } from "../../../../../../components";
import {
  buildNewSuppressionList,
  parseSuppressionsPreview,
} from "../../../../../../actions/newSuppressionList";
import ResetDialog from "../../ResetDialog";
import UploadIcon from "./UploadIcon";
import UploadLoaderIcon from "./UploadLoaderIcon";
import UploadErrorIcon from "./UploadErrorIcon";
import UploadSuccessIcon from "./UploadSuccessIcon";

const useStyles = makeStyles((theme) => ({
  fullHeight: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  dropZone: ({ color }) => ({
    height: "100%",
    width: "100%",
    color: color ? theme.palette[color].main : null,
  }),
}));

function getStatus(newSuppressionList, t) {
  const defaultStatus = {
    label: t("Upload a csv or txt file by drag-and-drop or clicking"),
    icon: UploadIcon,
    color: "primary",
  };
  return defaultStatus;
}

function UploadRecipientListFile() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [newFile, setNewFile] = useState();

  const newSuppressionList = useSelector((state) => state.newSuppressionList);
  const isStepDone = true;

  const { icon, label, color } = useMemo(
    () => getStatus(newSuppressionList, t),
    [newSuppressionList, t]
  );

  const classes = useStyles({ color });

  const parseFile = useCallback(
    (file) =>
      dispatch(
        parseSuppressionsPreview({
          file,
          errorMessage: t("Failed to parse {{fileName}}", {
            fileName: file.name,
          }),
        })
      ),
    [dispatch, t]
  );

  const onDropAccepted = useCallback(
    ([file]) => {
      parseFile(file);
    },
    [isStepDone, parseFile]
  );

  const onDropRejected = useCallback(
    ([{ file }]) => {
      dispatch(
        buildNewSuppressionList({
          errors: {
            file: t(
              'The file type must be csv or txt. You uploaded "{{type}}".',
              {
                type: file.type,
              }
            ),
          },
        })
      );
    },
    [dispatch, t]
  );

  return (
    <>
      <Box p={2} className={classes.fullHeight}>
        <DropZone
          dataTestId="suppression-list-file-selector"
          className={classNames(classes.dropZone, classes.fullHeight)}
          accept=".csv,.txt"
          multiple={false}
          onDropAccepted={onDropAccepted}
          onDropRejected={onDropRejected}
          Icon={icon}
          label={label}
          disabled={false}
        />
      </Box>
    </>
  );
}

export default memo(UploadRecipientListFile);
