import React, { memo, useState, useCallback, useMemo } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  ListItemSecondaryAction,
  IconButton,
  Popover,
  Typography,
  TablePagination,
} from "@material-ui/core";
// import { AttachFileIcon } from '@material-ui/icons';
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { useTranslation } from "react-i18next";

import { format } from "date-fns";
import DownloadReport from "./DownloadReport";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { REPORT_DOWNLOAD_PERMISSION } from "../../hooks/useAuthorized";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

function OpenReportFileListButton({ onClick, onDisabled }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleClick = () => {
    if (!onDisabled) onClick();
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <IconButton
        edge="end"
        aria-label="comments"
        onClick={() => handleClick()}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        <CloudDownloadIcon color={onDisabled ? "disabled" : "primary"} />
      </IconButton>
      {onDisabled ? (
        <Popover
          id="mouse-over-popover"
          className={classes.popover}
          classes={{
            paper: classes.paper,
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography>
            {/* {t("No report generated while campaign is running")} */}
            {t("The campaign has not been launched")}
          </Typography>
        </Popover>
      ) : (
        ""
      )}
    </>
  );
}

function CampaignList({
  campaigns,
  selectedCampaign,
  onClick,
  classes,
  openDownload,
  onPageChange,
  itemsPerPage,
  onItemsPerPageChange,
  page,
}) {
  const { campaignId } = selectedCampaign;

  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );
  const permissions = useSelector((state) => state.user.permissions);
  const permissionArray = permissions[activeDivisionId] || permissions[null];
  const canDownloadReport = permissionArray?.includes(
    REPORT_DOWNLOAD_PERMISSION
  );

  const [downloadDialog, setDownloadDialogState] = useState({
    open: false,
    campaignId: undefined,
  });

  const { t } = useTranslation();

  const handleClick = (id) => {
    if (id !== campaignId) {
      // Does not trigger onClick effect if already selected
      onClick(id);
    }
  };

  const openDownloadDialog = () => {
    setDownloadDialogState({ open: true, campaignId });
  };

  const onCloseDialog = useCallback(() =>
    setDownloadDialogState({ open: false, campaignId: undefined })
  );

  const getDownloadDialogState = useMemo(() => downloadDialog, [
    downloadDialog,
  ]);

  return (
    <List className={classes.list}>
      {/* Download Dialog Report File List Pop Up */}
      <DownloadReport state={getDownloadDialogState} onClose={onCloseDialog} />
      {campaigns?.data.map(
        (campaign, index) =>
          // At some initial renders the campaign store could contain campaigns without schedule
          campaign.schedule && (
            <ListItem
              onClick={() => handleClick(campaign._id)}
              button
              key={campaign._id}
              className={[
                classes.listItem,
                index % 2 === 1 && classes.grayBackground,
                classes.hover,
                campaignId === campaign._id && classes.selected,
              ].join(" ")}
            >
              <ListItemText
                primary={campaign.name}
                secondary={format(
                  new Date(campaign.schedule),
                  "MM/dd/yyyy HH:mm a"
                )}
                secondaryTypographyProps={{
                  style: { textAlign: "left" },
                }}
              />
              {canDownloadReport && (
                <Collapse
                  in={campaignId === campaign._id}
                  timeout="auto"
                  unmountOnExit
                >
                  <ListItemSecondaryAction>
                    <OpenReportFileListButton
                      onClick={openDownloadDialog}
                      onDisabled={campaign.status === "RUNNING"}
                    />
                  </ListItemSecondaryAction>
                </Collapse>
              )}
            </ListItem>
          )
      )}
      {campaigns && (
        <TablePagination
          colSpan={1}
          className={classes.paginator}
          count={campaigns.totalCount}
          page={page}
          rowsPerPage={itemsPerPage}
          rowsPerPageOptions={[20, 35, 50]}
          labelRowsPerPage={`${t("Max rows per page")}:`}
          SelectProps={{
            inputProps: { "aria-label": t("Max rows per page") },
          }}
          onChangePage={onPageChange}
          onChangeRowsPerPage={onItemsPerPageChange}
        />
      )}
    </List>
  );
}

export default memo(CampaignList);
