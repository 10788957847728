import React, { useMemo, useCallback, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { omit } from "lodash";
import {
  Grid,
  TextField,
  MenuItem,
  PhoneInput,
  Button,
  Box,
  Typography,
} from "../../../../components";
import DigitSelector from "./DigitSelector";
import { notify } from "../../../../actions/notification";
import {
  sendTestMessage,
  buildMasterTemplate,
} from "../../../../actions/masterTemplate";
import { format } from "date-fns";

function VoiceFields({
  voiceTemplate = {},
  errors = {},
  readOnly,
  onVoiceFieldChange,
  onVoiceRetryChange,
  eonsConfig,
  isEditingView,
  voiceActionStep,
  adhoc,
  voiceActionItems,
  callerIdDetail = [],
  onSaveAsDraftInitial,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const masterTemplate = useSelector((state) => state.masterTemplate ?? {});
  const { errors: templateErrors = {} } = masterTemplate;

  const {
    ttsVoiceType = "",
    callerId = "",
    // retries = "", // deprecated
    // repeatKey = "", // deprecated
    // liveAgentTransferKey = "", // deprecated
    // liveAgentTransferNumber = "", // deprecated
    optOutKey = "",
  } = voiceTemplate;

  const { retries } = voiceTemplate.freeswitch;

  const { voiceTalents } = eonsConfig;

  let ttsVoiceTypes = [];

  try {
    ttsVoiceTypes = JSON.parse(voiceTalents);
  } catch (err) {
    ttsVoiceTypes = [];

    dispatch(
      notify({
        type: "error",
        message: "something broke",
      })
    );
  }

  let { callerId: eonsCallerId = [] } = eonsConfig;
  let callerIdData = [];
  if (eonsCallerId) {
    const sortedCallerId = callerIdDetail.sort(function (a, b) {
      return new Date(b.lastUsed) - new Date(a.lastUsed);
    });
    eonsCallerId.forEach((caller) => {
      let index = callerIdDetail.findIndex((c) => c.callerId == caller);
      if (index == -1) {
        callerIdData.push({ value: caller.toString(), lastUsed: "Not Used" });
      }
    });
    sortedCallerId.forEach((scaller) => {
      callerIdData.push({
        value: scaller.callerId.toString(),
        lastUsed:
          "Last Used: " +
          format(new Date(scaller.lastUsed), "MM/dd/yyyy HH:mm"),
      });
    });
  }
  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);

  // deprecated
  const onLiveAgentTransferKeyChange = useCallback(
    (ev) =>
      onVoiceFieldChange(ev, {
        wipeErrors: [
          "liveAgentTransferKey",
          "liveAgentTransferNumber",
          "repeatKey",
          "optOutKey",
        ],
      }),
    [onVoiceFieldChange]
  );

  // deprecated
  const onLiveAgentTransferNumberChange = useCallback(
    (ev) =>
      onVoiceFieldChange(ev, {
        wipeErrors: ["liveAgentTransferKey", "liveAgentTransferNumber"],
      }),
    [onVoiceFieldChange]
  );

  //deprecated
  const onRepeatKeyChange = useCallback(
    (ev) =>
      onVoiceFieldChange(ev, {
        wipeErrors: ["liveAgentTransferKey", "repeatKey"],
      }),
    [onVoiceFieldChange]
  );

  const onOptOutKeyChange = useCallback(
    (ev) => {
      let newErrors = {};
      // Validate that DTMF doesn't match Opt out key
      const steps =
        masterTemplate?.voiceTemplate?.freeswitch?.dialPlan?.person?.steps;

      const match = steps?.find((step) => {
        return step.keys?.find((key) => key.key === ev.target.value);
      });

      if (match) {
        newErrors = {
          KeyShouldMismatch: "Should not match Opt-out key",
        };
      } else {
        newErrors = omit(templateErrors, "KeyShouldMismatch");
      }
      onVoiceFieldChange(ev, {
        wipeErrors: ["liveAgentTransferKey", "optOutKey"],
      });
      dispatch(
        buildMasterTemplate({
          errors: newErrors,
        })
      );
    },
    [onVoiceFieldChange, dispatch, templateErrors]
  );

  const [testVoice, setTestVoice] = useState("");
  const onTestVoiceChange = useCallback(
    (testVoice) => setTestVoice(testVoice),
    [setTestVoice]
  );

  const onSendTestVoice = useCallback(async () => {
    try {
      const data = await onSaveAsDraftInitial();

      await dispatch(
        sendTestMessage({
          channelType: "voice",
          data: {
            to: testVoice,
            from: voiceTemplate.callerId,
            testMessage: voiceTemplate.freeswitch.dialPlan.person.steps,
            templateId: voiceTemplate._id || data?.voiceTemplateId,
          },
        })
      );
    } catch (err) {
      dispatch(
        notify({
          type: "error",
          message: t("You must provide a valid test phone number."),
        })
      );
      throw err;
    }
  }, [dispatch, testVoice, voiceTemplate, t, onSaveAsDraftInitial]);

  const clearOptOutOption = () =>
    onOptOutKeyChange({
      target: {
        name: "optOutKey",
        value: "",
      },
    });

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} sm={6} md={6}>
        <TextField
          disabled={readOnly}
          data-test-id="master-template-voice-talent-field"
          select
          InputLabelProps={{ shrink: Boolean(ttsVoiceType) }}
          label={t("Voice Talent")}
          variant="outlined"
          size="small"
          fullWidth
          name="ttsVoiceType"
          value={ttsVoiceType}
          onChange={onVoiceFieldChange}
          error={Boolean(errors["voiceTemplate.ttsVoiceType"])}
          helperText={errors["voiceTemplate.ttsVoiceType"]}
          InputProps={readOnlyProps}
        >
          {ttsVoiceTypes.map((voiceType) => (
            <MenuItem
              key={voiceType.label}
              value={voiceType.key}
              data-test-id={`voicetype-id-${voiceType.key}`}
            >
              {voiceType.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6} sm={6} md={6}>
        <TextField
          disabled={readOnly}
          data-test-id="master-template-caller-id-field"
          select
          InputLabelProps={{ shrink: Boolean(callerId) }}
          label={t("Caller Id")}
          variant="outlined"
          size="small"
          fullWidth
          name="callerId"
          value={callerId}
          onChange={onVoiceFieldChange}
          error={Boolean(errors["voiceTemplate.callerId"])}
          helperText={errors["voiceTemplate.callerId"]}
          InputProps={readOnlyProps}
        >
          {callerIdData.map((callerId) => (
            <MenuItem
              key={callerId.value}
              value={callerId.value}
              data-test-id={`caller-id-${callerId.value}`}
            >
              {callerId.value + "(" + callerId.lastUsed + ")"}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={3} sm={3} md={3}>
        <DigitSelector
          data-test-id="master-template-retries-field"
          name="retries"
          value={retries}
          label={t("Retries")}
          error={errors["voiceTemplate.freeswitch.retries"]}
          onChange={onVoiceRetryChange}
          size={3}
          readOnly={readOnly}
        />
      </Grid>
      <Grid
        item
        xs={6}
        sm={6}
        md={6}
        style={{
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
        }}
      >
        <DigitSelector
          data-test-id="master-template-supress-key-field"
          name="optOutKey"
          value={optOutKey}
          label={t("Opt-out Key")}
          error={errors["voiceTemplate.optOutKey"]}
          onChange={onOptOutKeyChange}
          withSymbols
          readOnly={readOnly}
          optional
        />
      </Grid>

      <Grid
        item
        xs={3}
        sm={3}
        md={3}
        style={{ display: "flex", justifyContent: "end", marginTop: 15 }}
      >
        {voiceActionStep}
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        {voiceActionItems}
      </Grid>

      {/* Deprecated Section */}
      {/* <Grid item>
        <DigitSelector
          data-test-id="master-template-repeat-key-field"
          name="repeatKey"
          value={repeatKey}
          label={t("Repeat Key")}
          error={errors["voiceTemplate.repeatKey"]}
          onChange={onRepeatKeyChange}
          withSymbols
          readOnly={readOnly}
        />
      </Grid> */}
      {/* <Grid item>
        <DigitSelector
          data-test-id="master-template-live-agent-tranfer-key-field"
          name="liveAgentTransferKey"
          value={liveAgentTransferKey}
          label={t("Live Agent Transfer Key")}
          error={errors["voiceTemplate.liveAgentTransferKey"]}
          onChange={onLiveAgentTransferKeyChange}
          withSymbols
          optional
          readOnly={readOnly}
        />
      </Grid> */}
      {/* <Grid item>
        <TextField
          data-test-id="master-template-live-agent-tranfer-number-field"
          type="number"
          InputLabelProps={{ shrink: Boolean(liveAgentTransferNumber) }}
          label={t("Live Agent Transfer Number")}
          variant="outlined"
          size="small"
          fullWidth
          name="liveAgentTransferNumber"
          value={liveAgentTransferNumber}
          onChange={onLiveAgentTransferNumberChange}
          error={Boolean(errors["voiceTemplate.liveAgentTransferNumber"])}
          helperText={errors["voiceTemplate.liveAgentTransferNumber"]}
          InputProps={readOnlyProps}
        />
      </Grid> */}
      {
        <Grid item xs={12} sm={12} md={12}>
          <Box mt={2}>
            <Typography>
              {t(
                // eslint-disable-next-line max-len
                "You can only test voice calls that were saved as a template in advance. To test the latest voice template please save it first."
              )}
            </Typography>
          </Box>
          <Box mt={1}>
            <PhoneInput
              data-test-id="master-template-test-voice-number-field"
              label={t("Test Live Voice Sending")}
              country={"us"}
              value={testVoice}
              onChange={onTestVoiceChange}
              variant="outlined"
              size="small"
              disabled={!isEditingView && !adhoc}
              fullWidth
            />
          </Box>
          <Box mt={1}>
            <Button
              data-test-id="master-template-send-test-voice-button"
              variant="contained"
              color="primary"
              fullWidth
              onClick={onSendTestVoice}
              disabled={!isEditingView && !adhoc}
            >
              {t("Send Test")}
            </Button>
          </Box>
        </Grid>
      }
    </Grid>
  );
}

export default memo(VoiceFields);
