import * as api from "../api/suppression";

export const LIST_SUPPRESSIONS = "LIST_SUPPRESIONS";
export const CLEAR_SUPPRESSIONS = "CLEAR_SUPPRESSIONS";

export function clearSuppressions() {
  return { type: CLEAR_SUPPRESSIONS };
}

const createSuppressionListErrorObj = (type, err) => {
  // handle Joi validation errors
  const errObject = {};
  if (Array.isArray(err?.response?.data?.details)) {
    err.response.data.details.forEach((validationDetailObj) => {
      if (!errObject[type]) {
        errObject[type] = {};
      }
      errObject[type][validationDetailObj.path[1]] =
        validationDetailObj.message;
    });
    return errObject;
  }
  // handle other type of errors
  errObject[type] = { message: err.message };
  return errObject;
};

export function saveSuppressionList(type, body) {
  return async (dispatch, getState) => {
    const { user, storage, eonsConfig } = getState();
    const { emailConfig } = eonsConfig;
    const { email } = user;

    body.map((item) => {
      item.timestamp = new Date().toISOString();
      item.createdBy = email;

      if (type === "email") {
        //from field is from file upload
        item.domainAddress = item.from ? item.from : item.domainAddress;
        item.paid = String(storage.paid);
        if (item["from"]) delete item["from"];
        if (
          !emailConfig.domainAddress
            ?.toLowerCase()
            ?.includes(item?.domainAddress?.toLowerCase())
        ) {
          const errorMessage =
            "One or more domain address in the list does not match From Email list";
          const err = new Error(errorMessage);

          dispatch({
            type: LIST_SUPPRESSIONS,
            suppressionList: {
              errors: { email: { domainAddress: errorMessage } },
            },
          });
          throw err;
        }
      }
    });

    try {
      await api.saveSuppressionList({
        data: body,
        type,
      });
    } catch (err) {
      const errObject = createSuppressionListErrorObj(type, err);
      dispatch({
        type: LIST_SUPPRESSIONS,
        suppressionList: {
          errors: errObject,
        },
      });
      throw err;
    }
    dispatch(clearSuppressions());
  };
}

export function listSuppressions({
  shortCodes = [],
  params,
  domainAddresses = [],
  callerIds = [],
}) {
  return async (dispatch, _) => {
    let query = {
      shortcodes: shortCodes,
    };
    if (params.type === "email") {
      query = { domainAddresses: domainAddresses };
    } else if (params.type === "voice") {
      query = { callerIds };
    }
    const suppressionList = await api.getSuppressionList({
      ...query,
      ...params,
    });
    const count = await api.getSuppressionList({
      ...query,
      ...params,
      getCount: true,
    });

    dispatch({
      type: LIST_SUPPRESSIONS,
      suppressionList: { list: suppressionList, count },
    });
  };
}

export function deleteSuppression(suppressInfo) {
  return async (dispatch, getState) => {
    await api.deleteSuppressionById(suppressInfo);
  };
}
