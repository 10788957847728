import i18n from "i18next";
import { batch } from "react-redux";
import * as api from "../api/recipient";
import { BUILD_RECIPIENT_LIST, getRecipientList } from "./recipientList";

export function addRecipient(recipient, params) {
  return async (dispatch, getState) => {
    const counts = await api.addRecipient(recipient, params);
    const { recipientList } = getState();
    const {
      totalCount,
      failedCount,
      smsCount,
      voiceCount,
      ttyCount,
      emailCount,
    } = recipientList;

    const recipientListData = {
      totalCount: totalCount + counts.insertedCount,
      failedCount: failedCount + counts.failedCount,
      smsCount: smsCount + counts.smsCount,
      emailCount: emailCount + counts.emailCount,
      voiceCount: voiceCount + counts.voiceCount,
      ttyCount: ttyCount + counts.ttyCount,
    };

    dispatch({ type: BUILD_RECIPIENT_LIST, recipientListData });
    return counts;
  };
}

export function updateRecipient(recipientId, recipient, params) {
  return async (dispatch, getState) => {
    await api.updateRecipient(recipientId, recipient, params);
    dispatch({ type: BUILD_RECIPIENT_LIST });
  };
}

export function deleteRecipient(recipientId, params) {
  return async () => {
    await api.deleteRecipient(recipientId, params);
    return { type: BUILD_RECIPIENT_LIST };
  };
}
