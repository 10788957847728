import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setParams } from "../actions/params";

export const useTableActions = () => {
  const dispatch = useDispatch();

  const onPageChange = useCallback(
    (ev, page) => dispatch(setParams({ page })),
    [dispatch]
  );

  const onSortChange = useCallback(
    (orderBy, order) => dispatch(setParams({ orderBy, order, page: 0 })),
    [dispatch]
  );

  const onItemsPerPageChange = useCallback(
    (ev) => dispatch(setParams({ itemsPerPage: ev.target.value, page: 0 })),
    [dispatch]
  );

  const onSearchChange = useCallback(
    (search) => dispatch(setParams({ search, page: 0 })),
    [dispatch]
  );

  return {
    onPageChange,
    onSortChange,
    onItemsPerPageChange,
    onSearchChange,
  };
};
