import React, { memo, useState } from "react";
import {
  TextField,
  Autocomplete,
  createFilterOptions,
} from "../../../../components";

const filter = createFilterOptions();

function VoiceCustomerDataInput({
  isLocked,
  idx,
  label,
  value: prevValue,
  onChange,
  readOnlyProps,
  error,
  helperText,
  options,
}) {
  const [value, setValue] = useState(prevValue);
  return (
    <>
      <Autocomplete
        disabled={isLocked}
        value={value}
        size="small"
        onChange={(event, newValue) => {
          if (typeof newValue === "string") {
            setValue({
              title: newValue,
            });
            onChange(idx, newValue);
          } else if (newValue && newValue.inputValue) {
            // Create a new value from the user input
            setValue({
              title: newValue.inputValue,
            });
            onChange(idx, newValue.inputValue);
          } else {
            setValue(newValue);
            onChange(idx, newValue?.title ?? "");
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);

          const { inputValue } = params;
          // Suggest the creation of a new value
          const isExisting = options.some(
            (option) => inputValue === option.title
          );
          if (inputValue !== "" && !isExisting) {
            filtered.push({
              inputValue,
              title: `Add "${inputValue}"`,
            });
          }
          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        data-test-id="master-template-voice-data-auto-complete-field"
        options={options}
        getOptionLabel={(option) => {
          // Value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          // Add "xxx" option created dynamically
          if (option.inputValue) {
            return option.inputValue;
          }
          // Regular option
          return option.title;
        }}
        renderOption={(option, newVal) => {
          return <li>{option.title}</li>;
        }}
        sx={{ width: 300 }}
        freeSolo
        renderInput={(params) => (
          <TextField
            id="master-template-voice-data-field"
            data-test-id="master-template-voice-data-field"
            inputProps={readOnlyProps}
            size="small"
            fullWidth
            error={error}
            label={label}
            variant="outlined"
            helperText={
              helperText
                ? helperText
                : "If list does not include this variable, customer experience may be negatively affected."
            }
            {...params}
          />
        )}
      />
    </>
  );
}
export default memo(VoiceCustomerDataInput);
