import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  memo,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sub, endOfDay, startOfDay, format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { SmartTable, useMediaQuery } from "../../../components";
import { listApprovers, clearApprovers } from "../../../actions/approvers";
import { setParams } from "../../../actions/params";
import Filters from "./Filters";
import Actions from "./Actions";
import AdvancedApproverActions from "./AdvancedApproverActions";
import DeleteDialog from "../../../components/DeleteDialog";
import { deleteApprover } from "../../../actions/approver";
import useAuthorized, {
  APPROVER_UPDATE_PERMISSION,
  APPROVER_DELETE_PERMISSION,
  APPROVER_GET_PERMISSION,
} from "../../../hooks/useAuthorized";
import { useTableActions } from "../../../hooks/useTableActions";

const getColumns = ({
  t,
  onOpenDeleteDialog,
  authorizedToDoAnyAction,
  advancedApproverManagement = false,
  canEdit = false,
}) => {
  let approverColums = [
    {
      name: "name",
      label: t("Name"),
      sortable: true,
      width: 2,
      testId: "approver-name",
    },
    {
      name: "email",
      label: t("Email"),
      sortable: true,
      width: 2,
      testId: "approver-email",
    },
    {
      name: "sms",
      label: t("SMS"),
      sortable: true,
      width: 2,
      testId: "approver-sms",
    },
    {
      name: "approvedCampaignCounter",
      label: t("Approved Campaigns"),
      sortable: true,
      width: 2,
      testId: "approver-approvedCampaigns",
    },
    {
      name: "createdByName",
      label: t("Created By"),
      sortable: true,
      testId: "approver-createdBy",
    },
    {
      name: "createdAt",
      getter: (approver) => format(new Date(approver.createdAt), "MM/dd/yyyy"),
      label: t("Created At"),
      sortable: true,
      testId: "approver-createdAt",
    },
    {
      name: "modifiedAt",
      getter: (approver) => format(new Date(approver.modifiedAt), "MM/dd/yyyy"),
      label: t("Modified At"),
      sortable: true,
      testId: "approver-modifiedAt",
    },
  ];

  if (advancedApproverManagement && canEdit) {
    approverColums = approverColums.concat([
      {
        name: "Enable",
        getter: (approver) => <AdvancedApproverActions approver={approver} />,
        label: t("Enable"),
        sortable: false,
        align: "center",
      },
    ]);
  }

  if (authorizedToDoAnyAction) {
    return approverColums.concat([
      {
        name: "actions",
        getter: (approver) => (
          <Actions approver={approver} onDelete={onOpenDeleteDialog} />
        ),
        label: t("Actions"),
        sortable: false,
        align: "center",
      },
    ]);
  } else {
    return approverColums;
  }
};

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1190,
  },
  smallCell: {
    minWidth: 110,
    maxWidth: 150,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

function getParams(queryParams, activeDivisionId) {
  // pick the usable params, do not include extra params that are not used by this page
  const {
    search,
    page,
    itemsPerPage,
    orderBy,
    order,
    permission,
  } = queryParams;

  // add default values to the usable params
  // the end of the current day to include the whole day as the user would expect
  const defaultToDate = endOfDay(Date.now()).getTime();
  // the start of the day to include the whole day as the user would expect
  const defaultFromDate = startOfDay(
    sub(defaultToDate, { months: 2 })
  ).getTime();

  return {
    search: search ?? "",
    permission,
    page: page ?? 0,
    itemsPerPage: itemsPerPage ?? 20,
    orderBy: orderBy ?? "createdDate",
    order: order ?? -1,
    activeDivisionId,
  };
}

const getResource = (t) => t("Approvers");

function ApproversPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [approverToDelete, setApproverToDelete] = useState();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const resource = useMemo(() => getResource(t), [t]);

  const approvers = useSelector((state) => state.approvers);
  const queryParams = useSelector((state) => state.params);
  const {
    onItemsPerPageChange,
    onPageChange,
    onSortChange,
    onSearchChange
  } = useTableActions();

  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );

  const { advancedApproverManagement = false } = useSelector(
    (state) => state.eonsConfig.vendorConfig
  );

  // fill in missing query params with default values and the active division id
  // the default values are not persisted in the query string for a better user experience
  const params = useMemo(() => getParams(queryParams, activeDivisionId), [
    queryParams,
    activeDivisionId,
  ]);

  // clean up global data and params when the user leaves the page
  useEffect(
    // return a function which runs at component unmount
    () => () => dispatch(clearApprovers()),
    [dispatch]
  );

  // deleting related handlers
  const onOpenDeleteDialog = useCallback(
    (approver) => setApproverToDelete(approver),
    [setApproverToDelete]
  );

  const onCloseDeleteDialog = useCallback(() => setApproverToDelete(null), [
    setApproverToDelete,
  ]);

  const onDelete = useCallback(async () => {
    await dispatch(deleteApprover(approverToDelete, { activeDivisionId }));
    await dispatch(listApprovers(params));
    onCloseDeleteDialog();
  }, [
    dispatch,
    onCloseDeleteDialog,
    approverToDelete,
    params,
    activeDivisionId,
  ]);

  const canEdit = useAuthorized(APPROVER_UPDATE_PERMISSION);
  const canDelete = useAuthorized(APPROVER_DELETE_PERMISSION);
  const canView = useAuthorized(APPROVER_GET_PERMISSION);

  const authorizedToDoAnyAction = canEdit || canDelete || canView;

  // memoize the column getter for a perf boost
  const columns = useMemo(
    () =>
      getColumns({
        t,
        onOpenDeleteDialog,
        authorizedToDoAnyAction,
        advancedApproverManagement,
        canEdit,
      }),
    [
      t,
      onOpenDeleteDialog,
      authorizedToDoAnyAction,
      advancedApproverManagement,
      canEdit,
    ]
  );

  // refetch the master templates whenever the params change
  useEffect(() => {
    dispatch(listApprovers(params));
  }, [dispatch, params]);

  return (
    <Fragment>
      <SmartTable
        filters={<Filters params={params} onSearchChange={onSearchChange} />}
        containerStyles={{ maxHeight: 600 }}
        className={classes.table}
        columns={columns}
        data={approvers?.data}
        totalCount={approvers?.totalCount}
        notFoundMessage={t("No approvers found for your filters")}
        orderBy={params.orderBy}
        order={params.order}
        page={params.page}
        itemsPerPage={params.itemsPerPage}
        onSortChange={onSortChange}
        onPageChange={onPageChange}
        onItemsPerPageChange={onItemsPerPageChange}
        testId="approver"
      />
      <DeleteDialog
        open={Boolean(approverToDelete)}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
        name={approverToDelete?.name}
        resource={resource}
      />
    </Fragment>
  );
}

export default memo(ApproversPage);
