import React, { useMemo, useState, useCallback, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Page, SmartTable, useMediaQuery } from "../../../components";
import { listCampaigns, clearCampaigns } from "../../../actions/campaigns";
import { setParams } from "../../../actions/params";
import Filters from "./Filters";
import Actions from "./Actions";
import StatusIndicator from "./StatusIndicator";
import DeleteDialog from "../../../components/DeleteDialog";
import {
  deleteCampaign,
  cloneCampaign,
  stopCampaign,
  resumeCampaign,
} from "../../../actions/campaign";
import { getParams } from "../../../utils";
import { useTableActions } from "../../../hooks/useTableActions";

const getColumns = ({
  t,
  onOpenDeleteDialog,
  onClone,
  onStop,
  onResume,
  cloneAndDelete,
}) => [
  {
    name: "name",
    getter: (campaign) => campaign.name || "-",
    label: t("Name"),
    sortable: true,
    width: 2,
    testId: "campaign",
  },
  {
    name: "createdByName",
    label: t("Created By"),
    sortable: true,
    testId: "campaign",
  },
  {
    name: "createdAt",
    getter: (campaign) => format(new Date(campaign.createdAt), "MM/dd/yyyy"),
    label: t("Created At"),
    sortable: true,
    testId: "campaign",
  },
  {
    name: "permission",
    label: t("Permission"),
    sortable: true,
    testId: "campaign",
  },
  {
    name: "schedule",
    getter: (campaign) => {
      return campaign.schedule
        ? format(new Date(campaign.schedule), "MM/dd/yyyy hh:mm a")
        : "-";
    },
    label: t("Schedule"),
    sortable: true,
    width: 0.7,
    align: "center",
    testId: "campaign",
  },
  {
    name: "status",
    getter: (campaign) => (
      <StatusIndicator
        testId="campaign"
        status={campaign.status}
        comments={campaign.comments}
        doneStepIdx={campaign.stepIdx}
      />
    ),
    label: t("Status"),
    sortable: true,
    width: 0.7,
    align: "center",
    testId: "campaign",
  },
  {
    name: "actions",
    getter: (campaign) => (
      <Actions
        testId="campaign"
        campaign={campaign}
        onDelete={onOpenDeleteDialog}
        onClone={onClone}
        onStop={onStop}
        onResume={onResume}
        cloneAndDelete={cloneAndDelete}
      />
    ),
    label: t("Actions"),
    sortable: false,
    align: "center",
  },
];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1000,
  },
  smallCell: {
    minWidth: 110,
    maxWidth: 150,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

const getResource = (t) => t("Campaign");

function CampaignsPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [campaignToDelete, setCampaignToDelete] = useState();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const resource = useMemo(() => getResource(t), [t]);
  const {
    onItemsPerPageChange,
    onPageChange,
    onSortChange,
    onSearchChange
  } = useTableActions();

  const campaigns = useSelector((state) => state.campaigns);
  const queryParams = useSelector((state) => state.params);
  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );

  // fill in missing query params with default values and the active division id
  // the default values are not persisted in the query string for a better user experience
  const params = useMemo(() => getParams(queryParams, activeDivisionId), [
    queryParams,
    activeDivisionId,
  ]);

  // clean up global data and params when the user leaves the page
  useEffect(
    // return a function which runs at component unmount
    () => () => dispatch(clearCampaigns()),
    [dispatch]
  );

  // deleting related handlers
  const onOpenDeleteDialog = useCallback(
    (campaign) => setCampaignToDelete(campaign),
    [setCampaignToDelete]
  );

  const onStop = useCallback(
    async (campaign) => {
      await dispatch(stopCampaign(campaign));
      await dispatch(listCampaigns(params));
    },
    [dispatch, params]
  );

  const onResume = useCallback(
    async (campaign) => {
      await dispatch(resumeCampaign(campaign));
      await dispatch(listCampaigns(params));
    },
    [dispatch, params]
  );

  const onClone = useCallback(
    async (campaign) => {
      const cloneId = await dispatch(cloneCampaign(campaign));
      history.push(`/campaigns/${cloneId}/settings`);
    },
    [dispatch, history]
  );

  const onCloseDeleteDialog = useCallback(() => setCampaignToDelete(null), [
    setCampaignToDelete,
  ]);

  const onDelete = useCallback(async () => {
    await dispatch(deleteCampaign(campaignToDelete, { activeDivisionId }));
    await dispatch(listCampaigns(params));
    onCloseDeleteDialog();
  }, [
    dispatch,
    onCloseDeleteDialog,
    campaignToDelete,
    params,
    activeDivisionId,
  ]);

  const cloneAndDelete = useCallback(
    async (campaign) => {
      const cloneId = await dispatch(cloneCampaign(campaign));
      await dispatch(deleteCampaign(campaign, { activeDivisionId }));
      // await dispatch(listCampaigns(params));
      history.push(`/campaigns/${cloneId}/settings`);
    },
    [dispatch, history]
  );

  // memoize the column getter for a perf boost
  const columns = useMemo(
    () =>
      getColumns({
        t,
        onOpenDeleteDialog,
        onClone,
        onStop,
        onResume,
        cloneAndDelete,
      }),
    [t, onOpenDeleteDialog, onClone, onStop, onResume, cloneAndDelete]
  );

  // refetch the master templates whenever the params change
  useEffect(() => {
    dispatch(listCampaigns(params));
  }, [dispatch, params]);

  const onPermissionChange = useCallback(
    (ev) => dispatch(setParams({ permission: ev.target.value, page: 0 })),
    [dispatch]
  );

  const onFromDateChange = useCallback(
    (fromDate) =>
      dispatch(setParams({ fromDate: fromDate.getTime(), page: 0 })),
    [dispatch]
  );

  const onToDateChange = useCallback(
    (toDate) => dispatch(setParams({ toDate: toDate.getTime(), page: 0 })),
    [dispatch]
  );

  return (
    <Page p={2} screenSized={!isSmallScreen}>
      <SmartTable
        filters={
          <Filters
            testId="campaign"
            params={params}
            onSearchChange={onSearchChange}
            onPermissionChange={onPermissionChange}
            onFromDateChange={onFromDateChange}
            onToDateChange={onToDateChange}
          />
        }
        className={classes.table}
        columns={columns}
        data={campaigns?.data}
        totalCount={campaigns?.totalCount}
        notFoundMessage={t("No campaigns found for your filters")}
        orderBy={params.orderBy}
        order={params.order}
        page={params.page}
        itemsPerPage={params.itemsPerPage}
        onSortChange={onSortChange}
        onPageChange={onPageChange}
        onItemsPerPageChange={onItemsPerPageChange}
        testId="campaign"
      />
      <DeleteDialog
        open={Boolean(campaignToDelete)}
        onClose={onCloseDeleteDialog}
        onDelete={onDelete}
        name={campaignToDelete?.name}
        resource={resource}
      />
    </Page>
  );
}

export default memo(CampaignsPage);
