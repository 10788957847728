import React, { useCallback, Fragment, memo } from "react";
import { Switch, Tooltip } from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  APPROVER_UPDATE_PERMISSION,
  useAuthorized,
} from "../../../hooks/useAuthorized";
import {
  setApprover,
  updateApprover,
  getApprover,
  clearApprover,
} from "../../../actions/approver";
import { useTranslation } from "react-i18next";
import { getParams } from "../../../utils/url";

function AdvancedApproverActions({ approver }) {
  const { _id, approverActive = false } = approver;
  const { t } = useTranslation();
  const canEdit = useAuthorized(APPROVER_UPDATE_PERMISSION);
  const dispatch = useDispatch();
  const history = useHistory();
  const { advancedApproverManagement = false } = useSelector(
    (state) => state.eonsConfig.vendorConfig
  );

  const handleChange = useCallback(async (event) => {
    const { search } = getParams();
    await dispatch(getApprover(_id));
    await dispatch(
      setApprover({
        approverActive: approverActive === false ? true : false,
        errors: "Error Changing Status",
        modified: true,
      })
    );
    await dispatch(updateApprover());
    await dispatch(clearApprover());
    const url = search ? `/admin?search=${search}` : '/admin/';
    history.push(url);
  });

  return (
    <Fragment>
      {canEdit && advancedApproverManagement ? (
        <Tooltip title={t("Enable Approver")}>
          <Switch
            color="primary"
            checked={approverActive ? approverActive : false}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
        </Tooltip>
      ) : null}
    </Fragment>
  );
}

export default memo(AdvancedApproverActions);
