import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";

import {
  Grid,
  Paper,
  FormControl,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  FormGroup,
  Checkbox,
  CircularProgress,
  Box,
  Skeleton,
} from "../../../../components";

const useStyles = makeStyles((theme) => ({
  checkbox: {
    paddingBottom: 0,
    paddingTop: 0,
  },
  approversLabel: {
    marginBottom: theme.spacing(2),
  },
  noMargin: {
    minHeight: "0px !important",
    margin: "0px !important",
  },
  noPadding: {
    padding: "0px !important",
  },
}));

function CampaignApprovers({
  approvers = [],
  selectedApprovers = [],
  errors = {},
  readOnly,
  onApproverToggle,
  loading,
  launchPercentage,
  ...rest
}) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { campaignSelectAll = false } = useSelector(
    (state) => state.eonsConfig.vendorConfig
  );

  const emptyApproverErrors =
    approvers.length < 1 ? (
      <Grid item>
        <FormHelperText>
          No approvers are available, please contact your administrator.
        </FormHelperText>
      </Grid>
    ) : null;

  return (
    <Paper {...rest}>
      <Box p={2}>
        <Grid container spacing={2} alignItems="center" justify="space-between">
          <Grid item xs={10} md={11}>
            <FormControl error={Boolean(errors.approvers)} fullWidth>
              <FormLabel component="legend" className={classes.approversLabel}>
                {t("Approvers")}
              </FormLabel>
              {loading ? (
                <Skeleton height={40} />
              ) : (
                <FormGroup>
                  <Grid
                    container
                    spacing={2}
                    data-test-id="campaign-approvers-list"
                  >
                    {emptyApproverErrors}
                    {approvers.map((approver) => (
                      <Grid item key={approver._id}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              className={classes.checkbox}
                              color="primary"
                              disabled={campaignSelectAll}
                              checked={
                                selectedApprovers.indexOf(approver._id) !== -1
                              }
                              onChange={() =>
                                !readOnly && onApproverToggle(approver._id)
                              }
                              name={`approvers-checkbox-${approver.name}`}
                            />
                          }
                          label={approver.name}
                        />
                      </Grid>
                    ))}
                  </Grid>
                  {errors.approvers && (
                    <FormHelperText>{errors.approvers}</FormHelperText>
                  )}
                </FormGroup>
              )}
            </FormControl>
          </Grid>
          <Grid item xs>
            <CircularProgress
              size={55}
              color="warning"
              variant="determinate"
              value={launchPercentage}
              withLabel
              data-test-id="campaign-approval-status"
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}

export default memo(CampaignApprovers);
