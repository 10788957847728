import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from ".";

function ArchiveDialog({ onClose, onArchive, open, name, resource, ...rest }) {
  const { t } = useTranslation();

  return (
    <Dialog {...rest} open={open} onClose={onClose}>
      <DialogTitle>{t("Archive {{resource}}", { resource })}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("Are you sure you would like to archive {{name}}?", {
            name: name ?? t("your {{resource}}", { resource }),
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-test-id="archive-dialog-close-button">
          {t("Cancel")}
        </Button>
        <Button
          onClick={onArchive}
          color="secondary"
          data-test-id="archive-dialog-archive-button"
        >
          {t("Archive {{resource}}", { resource })}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ArchiveDialog);
