import React, { useCallback, useState, useEffect, useMemo, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { omit, intersection } from "lodash";
import { buildCampaign } from "../../../../actions/campaign";
import * as templatesApi from "../../../../api/masterTemplate";
import * as listsApi from "../../../../api/recipientLists";
import Fields from "./Fields";
import Preview from "./Preview";

const getRecipientHeaders = (recipientLists = []) =>
  intersection(
    ...recipientLists.map((recipientList) =>
      Object.keys(recipientList.recipientHeaders)
    )
  );

function TestCampaign() {
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaign);
  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );
  const paid = useSelector((state) => state.storage.paid);

  const {
    errors,
    name,
    permission,
    schedule,
    smsOptover,
    confirmServiceAddress,
    templateId,
    lists,
    bypassSuppression,
    bypassDndDns,
    isHighPriority,
    sendAsApproved,
    tags,
  } = campaign;

  const [masterTemplate, setMasterTemplate] = useState();
  const [recipientLists, setRecipientLists] = useState();

  const recipientHeaders = useMemo(() => getRecipientHeaders(recipientLists), [
    recipientLists,
  ]);
  const activeCompanyId = useSelector((state) => state.user.companyId);
  const vendorConfig = useSelector((state) => state.eonsConfig.vendorConfig);
  useEffect(() => {
    Promise.all(
      lists.map((recipientListId) =>
        listsApi.getRecipientList(recipientListId, {
          activeDivisionId,
          activeCompanyId,
        })
      )
    ).then((recipientLists) => setRecipientLists(recipientLists));
  }, [setRecipientLists, activeDivisionId, lists]);

  useEffect(() => {
    templatesApi
      .getMasterTemplate(templateId, { activeDivisionId, paid })
      .then((masterTemplate) => setMasterTemplate(masterTemplate));
  }, [setMasterTemplate, activeDivisionId, templateId]);

  // if user permissions are set to 'user' and the vendorConfig is set to disable 'user' level
  // permissions, then set the default permission of this campaign to division
  useEffect(() => {
    const { disableUserPermissions = [] } = vendorConfig;
    if (
      disableUserPermissions.includes("campaign") &&
      campaign.permission === "user"
    ) {
      dispatch(
        buildCampaign({
          permission: "division",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, vendorConfig]);

  const onFieldChange = useCallback(
    (ev) => {
      dispatch(
        buildCampaign({
          [ev.target.name]: ev.target.value,
          errors: omit(errors, ev.target.name),
        })
      );
    },
    [dispatch, errors]
  );

  const onFieldToggle = useCallback(
    (ev) => {
      dispatch(
        buildCampaign({
          [ev.target.name]: ev.target.checked,
          errors: omit(errors, ev.target.name),
        })
      );
    },
    [dispatch, errors]
  );

  return (
    <>
      <Fields
        name={name}
        permission={permission}
        schedule={schedule}
        smsOptover={smsOptover}
        recipientHeaders={recipientHeaders}
        confirmServiceAddress={confirmServiceAddress}
        bypassSuppression={bypassSuppression}
        bypassDndDns={bypassDndDns}
        isHighPriority={isHighPriority}
        sendAsApproved={sendAsApproved}
        tagss={tags}
        errors={errors}
        onChange={onFieldChange}
        onToggle={onFieldToggle}
        mb={2}
        data-test-id="campaign-settings-fields"
      />
      <Preview
        masterTemplate={masterTemplate}
        data-test-id="campaign-settings-preview"
      />
    </>
  );
}

export default memo(TestCampaign);
