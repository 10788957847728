import { Grid, Typography, makeStyles } from "@material-ui/core";
import React, { useCallback, useEffect, useMemo } from "react";
import { SmartTable } from "../../../components";
import Actions from "./Actions";
import { setParams } from "../../../actions/params";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { clearFolders, listFolders } from "../../../actions/foldersTemplates";
import { clearFolder, getFolder } from "../../../actions/folderTemplate";
import Filters from "./Filters";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    textDecoration: "none",
    color: "blue",
  },
  customBox: {
    height: 50,
    // border: "solid 1px red",
    paddingInline: "1rem",
    display: "flex",
    alignItems: "center",
  },
  breadcrumbIcon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
  table: {
    minWidth: 1190,
  },
}));

const getExtraColumns = ({ t, onOpenMoveDialog, onOpenDeleteDialog }) => [
  {
    name: "actions",
    getter: (templates) => (
      <Actions
        onMove={onOpenMoveDialog}
        onDelete={onOpenDeleteDialog}
        template={templates}
      />
    ),
    label: t("Actions"),
    sortable: false,
    width: 1,
    align: "center",
  },
];

const getColumns = ({ t, extraColumns = [] }) => {
  return [
    {
      name: "name",
      label: t("Template Name"),
      sortable: true,
      align: "center",
      testId: "folder-list-template-name",
    },
    {
      name: "createdDate",
      label: t("Created At"),
      getter: (template) =>
        template.createdDate
          ? format(new Date(template.createdDate), "MM/dd/yyyy HH:mm")
          : "-",
      sortable: true,
      align: "center",
      testId: "folder-list-template-createAt",
    },
    ...extraColumns,
  ];
};

export function getParams(queryParams) {
  const {
    templatesPage,
    templatesPerPage,
    templateOrderBy,
    templateOrder,
    templateSearch,
  } = queryParams;
  return {
    templatesPage: templatesPage ?? 0,
    templatesPerPage: templatesPerPage ?? 20,
    templateOrderBy: templateOrderBy ?? "createdDate",
    templateOrder: templateOrder ?? -1,
    templateSearch: templateSearch,
  };
}

export default function TemplateList({
  t,
  data,
  totalCount,
  onOpenMoveDialog,
  onOpenDeleteDialog,
  folderName,
  subFolderView,
  folderId,
}) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { activeDivisionId, paid } = useSelector((state) => state.storage);
  const extraColumns = useMemo(
    () => getExtraColumns({ t, onOpenMoveDialog, onOpenDeleteDialog }),
    [t, onOpenMoveDialog, onOpenDeleteDialog]
  );

  const columns = useMemo(() => getColumns({ t, extraColumns }), [
    t,
    extraColumns,
  ]);

  const queryParams = useSelector((state) => state.params);
  const params = useMemo(() => getParams(queryParams), [queryParams]);

  const onItemsPerPageChange = useCallback(
    (ev) =>
      dispatch(
        setParams({ templatesPerPage: ev.target.value, templatesPage: 0 })
      ),
    [dispatch]
  );

  const onPageChange = useCallback(
    (ev, page) => dispatch(setParams({ templatesPage: page })),
    [dispatch]
  );
  const onSortChange = useCallback(
    (templateOrderBy, templateOrder) =>
      dispatch(setParams({ templateOrderBy, templateOrder, templatesPage: 0 })),
    [dispatch]
  );

  const getFolders = async () => {
    if (!subFolderView) {
      await dispatch(clearFolders());
      await dispatch(listFolders({ activeDivisionId, paid, ...params }));
    } else {
      await dispatch(clearFolder());
      await dispatch(
        getFolder({ folderId, activeDivisionId, paid, ...params })
      );
    }
  };

  useEffect(() => {
    getFolders();
    return () => {};
  }, [dispatch, params]);

  const onSearchChange = useCallback(
    (search) =>
      dispatch(setParams({ templateSearch: search, templatesPage: 0 })),
    [dispatch]
  );

  return (
    <Grid container>
      <Grid item xs={12} sm={12} lg={12}>
        <Typography variant="h6" align="center">
          {`List of templates under folder '${folderName}'`}
        </Typography>
      </Grid>
      <Grid item>
        <SmartTable
          filters={<Filters params={params} onSearchChange={onSearchChange} />}
          className={classes.table}
          columns={columns}
          data={data}
          totalCount={totalCount}
          notFoundMessage={t("No templates under this folder")}
          orderBy={params.templateOrderBy}
          order={params.templateOrder}
          page={params.templatesPage}
          itemsPerPage={params.templatesPerPage}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onItemsPerPageChange={onItemsPerPageChange}
          testId="templates-on-folder-page"
          style={{ display: "table" }}
        />
      </Grid>
    </Grid>
  );
}
