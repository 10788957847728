import React, { useMemo, useCallback, useEffect, memo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import NavigationPrompt from "react-router-navigation-prompt";
import { useTranslation } from "react-i18next";
import { Wizard, Page, Box, NavigationDialog } from "../../../components";
import {
  getCampaign,
  buildCampaign,
  clearCampaign,
  saveCampaignLists,
  saveCampaignTemplate,
  saveCampaignSettings,
  saveCampaignApprovers,
  saveCampaignTargetFields,
} from "../../../actions/campaign";
import SelectRecipientList from "./SelectRecipientList";
import SelectMasterTemplate from "./SelectMasterTemplate";
import FillData from "./FillData";
import Settings from "./Settings";
import Approve from "./Approve";
import ConfirmationDialog from "../../../components/ConfirmationDialog";
import * as api from "../../../api/masterTemplate";
import { listCampaigns } from "../../../api/campaign";
import { notify } from "../../../actions/notification";
import i18next from "i18next";

const useStyles = makeStyles({
  content: {
    flex: 1,
    height: 0,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
  },
  wizard: {
    marginTop: "auto",
  },
});

const getSteps = ({
  t,
  path,
  saveLists,
  saveTemplate,
  saveTargetFields,
  saveSettings,
  disableTemplateEdit,
}) => [
  {
    label: t("Choose List"),
    path: `${path}/recipient-list`,
    component: SelectRecipientList,
    validate: saveLists,
  },
  {
    label: t("Choose Template"),
    path: `${path}/template`,
    component: SelectMasterTemplate,
    validate: saveTemplate,
    disable: disableTemplateEdit,
    nextPath: `${path}/data`,
    prevPath: `${path}/recipient-list`,
  },
  {
    label: t("Fill Data"),
    path: `${path}/data`,
    component: FillData,
    validate: saveTargetFields,
  },
  {
    label: t("Settings"),
    path: `${path}/settings`,
    component: Settings,
    validate: saveSettings,
  },
  {
    label: t("Submit for Approval"),
    path: `${path}/approval`,
    component: Approve,
  },
];

const CAMPAIGN_STATUS = {
  PENDING: "PENDING",
  RUNNING: "RUNNING",
  REJECTED: "REJECTED",
  CANCELLED: "CANCELLED",
  STOPPED: "STOPPED",
  COMPLETED: "COMPLETED",
  WAITING_FOR_APPROVAL: "WAITING_FOR_APPROVAL",
};

function SingleCampaignPage() {
  const dispatch = useDispatch();
  const campaign = useSelector((state) => state.campaign);
  const adhocTemplate = useSelector((state) => state.masterTemplate);
  const userId = useSelector((state) => state.user.id);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [disableTemplateEdit, setDisableTemplateEdit] = useState(false);
  const history = useHistory();
  const match = useRouteMatch();
  const { campaignId } = useParams();
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    stepIdx = -1,
    errors = {},
    status,
    createdById,
    modified,
    schedule,
    templateId,
    divisionId,
    paid,
    clonedFrom = "",
  } = campaign || {};
  const { errors: templateErrors = {} } = adhocTemplate || {};
  const allErrors = { ...errors, ...templateErrors };

  const scheduleDate = schedule ? new Date(schedule) : null;
  const presentDate = new Date();
  const dateIsDue = scheduleDate > presentDate; //returns false on null scheduleDate
  const confirmMessage = `Your campaign will launch at scheduled time as soon as it's approved`;

  const readOnly =
    status === CAMPAIGN_STATUS.RUNNING ||
    status === CAMPAIGN_STATUS.WAITING_FOR_APPROVAL ||
    status === CAMPAIGN_STATUS.COMPLETED ||
    (createdById && createdById !== userId);

  const saveLists = useCallback(async () => {
    const savedCampaignId = await dispatch(saveCampaignLists());
    if (!campaignId && savedCampaignId) {
      setTimeout(() => history.replace(`/campaigns/${savedCampaignId}`));
      return false;
    }
    return Boolean(savedCampaignId);
  }, [dispatch, campaignId, history]);

  const saveTemplate = useCallback(() => dispatch(saveCampaignTemplate()), [
    dispatch,
  ]);

  const saveSettings = useCallback(() => dispatch(saveCampaignSettings()), [
    dispatch,
  ]);

  const onSave = useCallback(() => dispatch(saveCampaignApprovers()), [
    dispatch,
  ]);

  const saveTargetFields = useCallback(
    () => dispatch(saveCampaignTargetFields()),
    [dispatch]
  );

  const disableTemplateSection = () => {
    if (clonedFrom.length) {
      return true;
    }
    return false;
  };

  const steps = useMemo(
    () =>
      getSteps({
        t,
        saveLists,
        saveTemplate,
        saveSettings,
        saveTargetFields,
        path: match.url,
        disableTemplateEdit: disableTemplateSection(),
      }),
    [
      t,
      saveLists,
      saveTemplate,
      saveTargetFields,
      saveSettings,
      match,
      clonedFrom,
    ]
  );

  const onStepChange = useCallback(
    (activeStepIdx) =>
      dispatch(buildCampaign({ activeStepIdx, modified: false, errors: {} })),
    [dispatch]
  );

  const shouldBlockNavigation = useCallback(
    (location, newLocation) => {
      const path = location?.pathname?.split("") ?? [];
      const newPath = newLocation?.pathname?.split("") ?? [];
      const isSamePage = path[0] === newPath[0] && path[1] === newPath[1];
      return !isSamePage && Boolean(modified);
    },
    [modified]
  );

  // unlocking associated template on campaign edit
  useEffect(() => {
    (async () => {
      try {
        // If only this is a RUNNING campaign and user is trying to edit it
        if (status === "RUNNING") {
          // fetch if the template is used by other running campaigns
          const { totalCount = 1 } = await listCampaigns({
            activeDivisionId: divisionId,
            paid,
            noPagination: true,
            status: ["RUNNING"],
            templateId,
          });

          if (totalCount > 1) {
            //Warn user and disable template edit (even if related templates are not actually locked)
            setDisableTemplateEdit(true);
            dispatch(
              notify(
                {
                  type: "warning",
                  message: i18next.t(
                    "Cannot edit the templates for this campaign, Templates are locked"
                  ),
                },
                5000
              )
            );
          } else {
            //unlock the associated master-template and sub-template
            const {
              emailTemplate = null,
              smsTemplate = null,
              customTemplate = null,
              voiceTemplate = null,
              ttyTemplate = null,
              inboundTemplate = null,
            } = await api.getMasterTemplate(templateId, {
              activeDivisionId: divisionId,
              paid,
            });

            const emailTemplateId = emailTemplate?._id;
            const smsTemplateId = smsTemplate?._id;
            const voiceTemplateId = voiceTemplate?._id;
            const ttyTemplateId = ttyTemplate?._id;
            const customTemplateId = customTemplate?._id;
            const inboundTemplateId = inboundTemplate?._id;

            const updatedMasterTemplate = await api.updateMasterTemplateStatus({
              masterTemplateId: templateId,
              status: "COMPLETED",
            });
            if (emailTemplateId) {
              await api.unlockSubTemplate({
                id: emailTemplateId,
                isLocked: false,
                paid,
              });
            }
            if (smsTemplateId) {
              await api.unlockSubTemplate({
                id: smsTemplateId,
                isLocked: false,
                paid,
              });
            }
            if (voiceTemplateId) {
              await api.unlockSubTemplate({
                id: voiceTemplateId,
                isLocked: false,
                paid,
              });
            }
            if (ttyTemplateId) {
              await api.unlockSubTemplate({
                id: ttyTemplateId,
                isLocked: false,
                paid,
              });
            }
            if (inboundTemplateId) {
              await api.unlockSubTemplate({
                id: inboundTemplateId,
                isLocked: false,
                paid,
              });
            }
            if (customTemplateId) {
              await api.unlockSubTemplate({
                id: customTemplateId,
                isLocked: false,
                paid,
              });
            }
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    })();

    return () => {};
  }, [templateId]);

  useEffect(() => {
    dispatch(getCampaign(campaignId));
    return () => dispatch(clearCampaign());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (!campaign) {
    return null;
  }

  if (readOnly && !dateIsDue) {
    return (
      <Page screenSized p={2} className={classes.content}>
        <Approve readOnly />
      </Page>
    );
  }

  const currStep = steps[Math.min(steps.length - 1, stepIdx + 1)];

  return (
    <>
      <Page screenSized>
        <Wizard
          steps={steps}
          className={classes.wizard}
          doneStepIdx={stepIdx}
          onStepChange={onStepChange}
          disableNext={Object.keys(allErrors).length}
          onSave={async () => {
            const res = await onSave();
            if (res) {
              setOpenConfirmationDialog(true);
            }
          }}
          errors={allErrors}
          pushHistory
        />
        <Box p={2} className={classes.content}>
          <Switch>
            {steps.slice(0, stepIdx + 2).map((step) => (
              <Route
                key={step.path}
                path={step.path}
                component={step.component}
              />
            ))}
            <Redirect to={currStep.path} />
          </Switch>
        </Box>
        <ConfirmationDialog
          open={openConfirmationDialog}
          message={confirmMessage}
          onClose={() => setOpenConfirmationDialog(false)}
        />
      </Page>
      <NavigationPrompt when={shouldBlockNavigation}>
        {({ onConfirm, onCancel, isActive }) => (
          <NavigationDialog
            onConfirm={onConfirm}
            onCancel={onCancel}
            open={isActive}
          />
        )}
      </NavigationPrompt>
    </>
  );
}

export default memo(SingleCampaignPage);
