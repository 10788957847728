import {
  Button,
  Grid,
  Box,
  Paper,
  List,
  ListItem,
  Typography,
  ListItemText,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@material-ui/core";
import React, { memo, useEffect, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Page } from "../../components";
import { makeStyles } from "@material-ui/core/styles";
import { batch, useDispatch, useSelector } from "react-redux";
import {
  clearCampaignRelatedRecipients,
  getReport,
} from "../../actions/report";
import { useCallback } from "react";
import Search from "../../components/Search";
// import { PieChart, Pie, ResponsiveContainer, Cell } from "recharts";
import { Refresh } from "../../icons";
import { listCampaigns } from "../../actions/campaigns";
import { clearCampaign, getCampaign } from "../../actions/campaign";
import { setParams } from "../../actions/params";
import { getCampaignRelatedRecipients } from "../../actions/report";
import _ from "lodash";
import * as reportsApi from "../../api/report";
import { baseURL } from "../../api/api";
import queryString from "query-string";
// import { DateTimePicker } from "../../components";
import { format } from "date-fns";
import useAuthorized, {
  REPORT_GET_PERMISSION,
} from "../../hooks/useAuthorized";
import SearchBar from "./SearchBar";
import CampaignList from "./CampaignList";
import ReportChart from "./ReportChart";
import { useTableActions } from "../../hooks/useTableActions";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    overflow: "auto",
    flex: 1,
  },
  listTitle: {
    marginTop: 15,
    marginRight: 10,
    marginLeft: 10,
    marginBottom: 3,
    fontSize: 18,
    color: theme.palette.grey[500],
  },
  grayBackground: {
    backgroundColor: theme.palette.grey[100],
  },
  hover: {
    "&:hover": {
      backgroundColor: "#24A0EA40",
    },
  },
  listItem: {
    overflow: "hidden",
  },
  selected: {
    backgroundColor: "#24A0EA40",
  },
  list: {
    height: 750,
    overflowY: "auto",
  },
}));

// function Chart({ data = [], onCellClick, contactType }) {
//   data = data.filter((i) => i.value !== 0);
//   const COLORS = [
//     "#0088FE",
//     "#00C49F",
//     "#FFBB28",
//     "#FF8042",
//     "#FF2864",
//     "#BB9237",
//     "#ABF618",
//     "#179560",
//   ];
//   return (
//     <ResponsiveContainer width="100%" height={400}>
//       <PieChart>
//         <Pie
//           data={data}
//           cx="50%"
//           cy="50%"
//           isAnimationActive={false}
//           outerRadius={110}
//           fill="#8884d8"
//           dataKey="value"
//           label={({
//             cx,
//             cy,
//             midAngle,
//             innerRadius,
//             outerRadius,
//             value,
//             name,
//           }) => {
//             const RADIAN = Math.PI / 180;
//             const radius = 25 + innerRadius + (outerRadius - innerRadius);
//             const x = cx + radius * Math.cos(-midAngle * RADIAN);
//             const y = cy + radius * Math.sin(-midAngle * RADIAN);
//             return (
//               <text
//                 x={x}
//                 y={y}
//                 fill="black"
//                 textAnchor={x > cx ? "start" : "end"}
//                 dominantBaseline="central"
//               >
//                 {`${normalizeReportStatus(name)}: ${value}%`}
//               </text>
//             );
//           }}
//         >
//           {data.map((entry, index) => (
//             <Cell
//               onClick={() => onCellClick(entry, contactType)} // list based on status name
//               key={`cell-${index}`}
//               fill={COLORS[index % COLORS.length]}
//             />
//           ))}
//         </Pie>
//       </PieChart>
//     </ResponsiveContainer>
//   );
// }

function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const reportTypeTrans = {
  email: "Email",
  sms: "SMS",
  voice: "Voice",
  tty: "TTY",
  custom_channel: "custom_channel",
};

function ReportsPage() {
  const canGet = useAuthorized(REPORT_GET_PERMISSION);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const campaigns = useSelector((state) => state.campaigns);
  const campaign = useSelector((state) => state.campaign);
  const {
    vendorConfig: { canSearchRecipientOnReport = false },
  } = useSelector((state) => state.eonsConfig);

  const campaignSearch = useSelector((state) => state.params.campaignSearch);
  const fromDate = useSelector((state) => state.params.fromDate);
  const toDate = useSelector((state) => state.params.toDate);
  const recipientSearch = useSelector((state) => state.params.recipientSearch);
  const page = useSelector((state) => state.params.page) ?? 0;
  const itemsPerPage = useSelector((state) => state.params.itemsPerPage) ?? 20;
  const [reportRefreshDate, setReportRefreshDate] = useState(null);
  const {
    onItemsPerPageChange,
    onPageChange,
  } = useTableActions();

  const configuredChannels = useMemo(() => {
    return {
      email: Boolean(campaign?.hasOwnProperty("emailTargetFieldsSetup")),
      sms: Boolean(campaign?.hasOwnProperty("smsTargetFieldsSetup")),
      voice: campaign?.voiceCount > 0,
      tty: campaign?.ttyCount > 0,
      custom_channel: campaign?.customCount > 0,
    };
  }, [campaign]);

  const campaignId = useSelector((state) => state.params.campaignId);
  const {
    // email: emailReport,
    // sms: smsReport,
    // voice: voiceReport,
    // tty: ttyReport,
    recipients,
  } = useSelector((state) => state.report);

  const report = useSelector((state) => state.report);

  const [tableData, setTableData] = useState(undefined);

  const [dialogState, setDialogState] = useState({
    state: false,
    singleRecipient: null,
    eventType: undefined,
  });

  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );

  const [listCampaignBaseSearchParams] = useState({
    order: -1,
    orderBy: "schedule",
    activeDivisionId,
    schedule: true,
    status: ["COMPLETED", "STOPPED", "RUNNING"],
  });

  const baseReportParams = useMemo(
    () => ({
      activeDivisionId,
    }),
    [activeDivisionId]
  );

  const onSetCampaignAndGetReport = useCallback(
    (campaignId) => {
      batch(() => {
        dispatch(clearCampaignRelatedRecipients());
        dispatch(clearCampaign());
        dispatch(getCampaign(campaignId));
        dispatch(setParams({ campaignId }));
        setReportRefreshDate(new Date());
        dispatch(getReport(campaignId, baseReportParams));
      });
    },
    [dispatch, baseReportParams]
  );

  const refresh = useCallback(
    (campaignId) => {
      setReportRefreshDate(new Date());
      dispatch(getReport(campaignId, baseReportParams));
    },
    [dispatch, baseReportParams]
  );

  const getDownloadLink = useCallback(
    ({ onlyLatest, contactType, recipientId, eventType }) => {
      if (!canGet) {
        return;
      }
      return queryString.stringifyUrl({
        url: `${baseURL}/api/v1/reports/download/${campaignId}`,
        query: {
          onlyLatest,
          contactType,
          recipientId,
          eventType,
          activeDivisionId,
        },
      });
    },
    [campaignId, activeDivisionId, canGet]
  );

  const onCellClick = useCallback(
    async (entry, contactType) => {
      if (!canGet) {
        return;
      }
      const report = await reportsApi.getReport(campaignId, {
        ...baseReportParams,
        contactType,
        eventType: entry.event,
        onlyLatest: true,
        limit: 20,
      });
      entry = { ...entry, event: entry.event.toLowerCase() };
      setTableData({
        contactType,
        report: report[entry.event]
          ? report[entry.event].map((r) => ({
              ...r,
              status: normalizeReportStatus(r.status),
            }))
          : [],
      });
      setDialogState({
        state: true,
        singleRecipient: null,
        eventType: entry.event,
      });
    },
    [setTableData, baseReportParams, campaignId, canGet]
  );

  const onRecipientClick = useCallback(
    async (recipient) => {
      const contactType = recipient.fields?.contactType;
      const report = await reportsApi.getReport(campaignId, {
        ...baseReportParams,
        recipientId: recipient._id,
        contactType,
        onlyLatest: false,
      });

      if (report) {
        const eventKeys = Object.keys(report);
        const eventArrays = eventKeys.map((eventKey) => {
          return report[eventKey].map((event) => ({
            ...event,
            status: normalizeReportStatus(event.status),
          }));
        });
        setTableData({ contactType, report: _.flatten(eventArrays) });
        setDialogState({
          state: true,
          singleRecipient: recipient._id,
          eventType: undefined,
        });
      }
    },
    [baseReportParams, campaignId]
  );

  const onRecipientReportSearch = useCallback(
    async (search, event, contactType) => {
      const report = await reportsApi.getReport(campaignId, {
        ...baseReportParams,
        contactType,
        onlyLatest: true,
        eventType: event,
        search: search === "" ? undefined : search,
      });
      setTableData({
        contactType,
        report: report[event]
          ? report[event].map((r) => ({
              ...r,
              status: normalizeReportStatus(r.status),
            }))
          : [],
      });
    },
    [setTableData, baseReportParams, campaignId]
  );

  useEffect(() => {
    dispatch(
      listCampaigns({
        ...listCampaignBaseSearchParams,
        search: campaignSearch,
        toDate,
        fromDate,
        itemsPerPage,
        page,
      })
    );
  }, [
    dispatch,
    listCampaignBaseSearchParams,
    campaignSearch,
    fromDate,
    toDate,
    itemsPerPage,
    page,
  ]);

  useEffect(() => {
    if (campaignId && campaignId !== campaign?._id) {
      batch(() => {
        dispatch(clearCampaignRelatedRecipients());
        dispatch(clearCampaign());
        dispatch(getCampaign(campaignId));
        setReportRefreshDate(new Date());
        dispatch(getReport(campaignId, baseReportParams));
      });
    }
    return () => dispatch(clearCampaign());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, baseReportParams]);

  useEffect(() => {
    if (campaign && recipientSearch) {
      if (!canGet) {
        return;
      }
      dispatch(
        getCampaignRelatedRecipients(campaign._id, {
          ...baseReportParams,
          search: recipientSearch,
        })
      );
    }
  }, [dispatch, baseReportParams, recipientSearch, campaign, canGet]);

  return (
    <Page title={t("Reports page")}>
      <Dialog
        open={dialogState.state}
        maxWidth="md"
        onClose={() => setDialogState({ state: false })}
      >
        {Array.isArray(tableData?.report) && tableData.contactType && (
          <>
            <DialogTitle>{capitalize(tableData.contactType)}</DialogTitle>
            <DialogContent dividers>
              <TableContainer>
                <Table className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("Name")}</TableCell>
                      <TableCell>{capitalize(tableData.contactType)}</TableCell>
                      <TableCell>{t("Status")}</TableCell>
                      <TableCell>{t("Timestamp")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tableData.report.map((row) => (
                      <TableRow key={row.mbid}>
                        <TableCell component="th" scope="row">
                          {row?.name}
                        </TableCell>
                        <TableCell align="right">{row?.contact}</TableCell>
                        <TableCell align="right">{row?.status}</TableCell>
                        <TableCell align="right">
                          {format(
                            new Date(row?.timestamp),
                            "MM/dd/yyyy HH:mm a"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              {!dialogState.singleRecipient && (
                <Search
                  onSearch={(search) =>
                    onRecipientReportSearch(
                      search,
                      dialogState.eventType,
                      tableData.contactType
                    )
                  }
                  variant="outlined"
                  label={t("Search events...")}
                  size="small"
                  fullWidth
                />
              )}
              {/* <Button
                component="a"
                href={getDownloadLink({
                  onlyLatest: dialogState.singleRecipient ? false : true,
                  contactType: tableData.contactType,
                  eventType: dialogState.eventType,
                  recipientId: dialogState.singleRecipient
                    ? dialogState.singleRecipient
                    : undefined,
                })}
                color="primary"
                variant="contained"
                download
              >
                {t("Download")}
              </Button> */}
              <Button
                onClick={() => setDialogState({ state: false })}
                color="secondary"
                variant="contained"
              >
                {t("Close")}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Box p={3} className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={5} md={4} lg={3}>
            <SearchBar
              datePicker={{
                toDate,
                fromDate,
                onAccept: {
                  createdAfter: (fromDateVal) => {
                    const fromDateInMillis = fromDateVal.getTime();
                    if (fromDateInMillis !== fromDate) {
                      dispatch(
                        setParams({ fromDate: fromDateInMillis, page: 0 })
                      );
                    }
                  },
                  createBefore: (toDateVal) => {
                    const toDateInMillis = toDateVal.getTime();
                    if (toDateInMillis !== toDate) {
                      dispatch(setParams({ toDate: toDateInMillis, page: 0 }));
                    }
                  },
                },
              }}
              search={{
                onSearch: (campaignSearch) =>
                  dispatch(setParams({ campaignSearch, page: 0 })),
                campaignSearch,
              }}
            />

            <Paper>
              <Typography className={classes.listTitle}>
                {t("Recent Campaigns")}
              </Typography>
              <Divider />
              <CampaignList
                onClick={onSetCampaignAndGetReport}
                campaigns={campaigns}
                onPageChange={onPageChange}
                onItemsPerPageChange={onItemsPerPageChange}
                selectedCampaign={{
                  campaignId,
                }}
                classes={classes}
                itemsPerPage={itemsPerPage}
                page={page}
              />
            </Paper>
          </Grid>
          <Grid container item xs={7} md={8} lg={9} spacing={2}>
            {campaign && campaign.schedule && (
              <>
                <Grid item xs={12} md={12} lg={12}>
                  <Box
                    m={1}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <div>
                      <Typography variant="h4">{campaign.name}</Typography>
                      <Typography>
                        {format(
                          new Date(campaign.schedule),
                          "MM/dd/yyyy HH:mm a"
                        )}
                      </Typography>
                    </div>
                    {reportRefreshDate && (
                      <Button
                        onClick={() => refresh(campaignId)}
                        size="medium"
                        variant="outlined"
                        startIcon={<Refresh />}
                      >
                        {t("Refresh") +
                          ` (${format(reportRefreshDate, "HH:mm:ss")})`}
                      </Button>
                    )}

                    {/* <Button
                      component="a"
                      href={getDownloadLink({ onlyLatest: true })}
                      color="primary"
                      size="medium"
                      variant="contained"
                      download
                      disabled={!canGet}
                    >
                      {t("Download report")}
                    </Button> */}
                  </Box>
                </Grid>
                <Grid item xs={canGet && canSearchRecipientOnReport ? 9 : 12}>
                  {Object.keys(configuredChannels).map(
                    (type) =>
                      configuredChannels[type] && (
                        <ReportChart
                          key={type}
                          channelType={type}
                          report={report[type]}
                          chartData={getChartData(report[type])}
                          onCellClick={onCellClick}
                          contactType={reportTypeTrans[type]}
                        />
                      )
                  )}
                </Grid>
                {canGet && canSearchRecipientOnReport && (
                  <Grid item xs={3}>
                    <Paper>
                      <Search
                        variant="outlined"
                        label={t("Search for a recipient")}
                        onSearch={(recipientSearch) =>
                          dispatch(setParams({ recipientSearch }))
                        }
                        defaultValue={recipientSearch}
                        fullWidth
                      />
                      <Typography className={classes.listTitle}>
                        {t("Recipients")}
                      </Typography>
                      <Divider />
                      <List className={classes.list} style={{ height: 640 }}>
                        {recipients?.data.map((recipient, index) => (
                          <ListItem
                            onClick={() => onRecipientClick(recipient)}
                            button
                            key={recipient._id}
                            className={[
                              classes.listItem,
                              index % 2 === 1 && classes.grayBackground,
                              classes.hover,
                            ].join(" ")}
                          >
                            <ListItemText
                              primary={recipient.fields.name}
                              secondary={`${recipient.fields.contactType}: ${recipient.fields.contact}`}
                              secondaryTypographyProps={{
                                style: { textAlign: "right" },
                              }}
                            />
                          </ListItem>
                        ))}
                      </List>
                    </Paper>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Page>
  );
}

function renameProcessedTextToSent(status) {
  if (status?.trim().toLowerCase() === "processed") {
    return "Sent";
  }

  return status;
}

function normalizeReportStatus(status) {
  status = renameProcessedTextToSent(status);
  const words = status.match(/[a-z]+|\d+/gi);
  return words
    ? words.reduce(
        (acc, cur) =>
          acc + cur.charAt(0).toUpperCase() + cur.slice(1).toLowerCase(),
        ""
      )
    : status;
}

function getChartData(report = {}) {
  const chartItems = [];
  for (const status in report) {
    if (status === "rawData") continue;
    const capitalizedName = status.charAt(0).toUpperCase() + status.slice(1);
    const statusPercentage = report[status];
    const chartItem = {
      name: capitalizedName,
      value: statusPercentage,
      event: status,
      raw: report.rawData[status],
    };
    chartItems.push(chartItem);
  }
  return chartItems;
}

export default memo(ReportsPage);
