import React, { useMemo, useCallback, useEffect, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import {
  AppBar,
  Notification,
  Page,
  SmartTable,
  Typography,
  useMediaQuery,
} from "../../../components";
import { setParams } from "../../../actions/params";
import { listPendingApprovalCampaigns } from "../../../actions/pendingApprovalCampaigns";
import Filters from "./Filters";
import Actions from "./Actions";
import { useHistory } from "react-router-dom";
import { useTableActions } from "../../../hooks/useTableActions";

const getColumns = ({ t }) => [
  {
    name: "name",
    getter: (campaign) => campaign.name || "-",
    label: t("Name"),
    sortable: true,
    width: 2,
    testId: "campaign",
  },
  {
    name: "schedule",
    getter: (campaign) => {
      return campaign.schedule
        ? format(new Date(campaign.schedule), "yyyy-MM-dd HH:MM")
        : "-";
    },
    label: t("Schedule"),
    sortable: true,
    width: 0.7,
    align: "center",
    testId: "campaign",
  },
  {
    name: "Schedule",
    getter: (campaign) => campaign?.createdByName || "-",
    label: t("Created By"),
    sortable: true,
    testId: "campaign",
  },
  {
    name: "actions",
    getter: (campaign) => <Actions testId="campaign" campaign={campaign} />,
    label: t("Actions"),
    sortable: false,
    align: "center",
  },
];

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1000,
  },
  smallCell: {
    minWidth: 110,
    maxWidth: 150,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
  title: {
    padding: 15,
    fontWeight: "bolder",
    color: theme.palette.primary.main,
  },
}));

function getPendingApprovalParams(queryParams) {
  // pick the usable params, do not include extra params that are not used by this page
  const { search, page, itemsPerPage, orderBy, order } = queryParams;

  return {
    search: search ?? "",
    page: page ?? 0,
    itemsPerPage: itemsPerPage ?? 20,
    orderBy: orderBy ?? "createdAt",
    order: order ?? -1,
  };
}

function ListPage() {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(false);
  const history = useHistory();

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const campaigns = useSelector((state) => state.pendingApprovalCampaigns);
  const queryParams = useSelector((state) => state.params);
  const {
    onItemsPerPageChange,
    onPageChange,
    onSortChange,
    onSearchChange
  } = useTableActions();

  // fill in missing query params with default values and the active division id
  // the default values are not persisted in the query string for a better user experience
  const params = useMemo(() => getPendingApprovalParams(queryParams), [
    queryParams,
  ]);

  // memoize the column getter for a perf boost
  const columns = useMemo(
    () =>
      getColumns({
        t,
      }),
    [t]
  );

  useEffect(() => {
    loadItems();
  }, [dispatch, params]);

  const loadItems = async () => {
    try {
      await dispatch(listPendingApprovalCampaigns(params));
    } catch (error) {
      setIsError(true);
      setTimeout(() => {
        history.replace("/login");
      }, 5000);
    }
  };

  return (
    <>
      <AppBar position="static" color="inherit">
        <Typography variant="h5" className={classes.title}>
          {t(`Approver Dashboard`)}
        </Typography>
      </AppBar>

      <Page p={2} screenSized={!isSmallScreen}>
        <SmartTable
          filters={
            <Filters
              testId="campaign"
              params={params}
              onSearchChange={onSearchChange}
            />
          }
          className={classes.table}
          columns={columns}
          data={campaigns?.data}
          totalCount={campaigns?.totalCount}
          notFoundMessage={t("No campaigns found for your filters")}
          orderBy={params.orderBy}
          order={params.order}
          page={params.page}
          itemsPerPage={params.itemsPerPage}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          onItemsPerPageChange={onItemsPerPageChange}
          testId="pending-approval-campaign"
        />
      </Page>

      {isError && (
        <Notification
          notification={{
            message: "Approver Link is expired, Redirecting to Login...",
            type: "error",
          }}
        />
      )}
    </>
  );
}

export default memo(ListPage);
