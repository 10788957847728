import React, {
  memo,
  useCallback,
  useState,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Page,
  DeleteDialog,
  useMediaQuery,
  Grid,
  Breadcrumbs,
  Typography,
  Button,
  FormDialog,
  Autocomplete,
  TextField,
} from "../../../components";
import { useDispatch, useSelector } from "react-redux";
import * as _ from "lodash";
import { AddBox, NavigateBefore } from "@material-ui/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import {
  clearFolders,
  createFolder,
  deleteFolderById,
  listFolders,
  updateFolder,
  moveTemplateToFolder,
} from "../../../actions/foldersTemplates";
import { clearFolder, getFolder } from "../../../actions/folderTemplate";
import DisplayFolders from "./DisplayFolders";
import * as api from "../../../api/foldersTemplates";
import MoveTemplateDialog from "./MoveTemplateDialog";
import TemplateList, { getParams } from "./TemplateList";
import { notify } from "../../../actions/notification";
import { deleteMasterTemplate } from "../../../actions/masterTemplate";
import { setParams } from "../../../actions/params";

const useStyles = makeStyles((theme) => ({
  link: {
    display: "flex",
    textDecoration: "none",
    color: "blue",
  },
  customBox: {
    height: 50,
    paddingInline: ".2rem",
    display: "flex",
    alignItems: "center",
  },
  breadcrumbIcon: {
    marginRight: theme.spacing(0.5),
    width: 20,
    height: 20,
  },
}));

const ROOT = { _id: "root", name: "root folders" };
const ListFolders = ({ subFolderView = false }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { folderId = null } = useParams();
  const { activeDivisionId, paid } = useSelector((state) => state.storage);
  const {
    data: folders,
    masterTemplates: rootTemplates,
    totalCount: rootTemplatesCounts,
  } = useSelector((state) => state.foldersTemplates) || {};
  const {
    foundFolder = {},
    subFolders = [],
    masterTemplates = [],
    totalCount: folderTemplatesCounts,
  } = useSelector((state) => state.folderTemplate) || {};

  const [moved, hasMoved] = useState(false);
  const [localState, setLocalState] = useState({
    openForm: false,
    folderToEdit: null,
    folderToDelete: null,
    showDeleteDialog: false,
    deleteMessage: "",
    templateToMove: null,
    breadCrumbs: [ROOT],
    lineage: [],
  });

  const templatesRef = useRef();

  const formFields = {
    name: {
      type: "string",
    },
  };

  const queryParams = useSelector((state) => state.params);
  const params = useMemo(() => getParams(queryParams), [queryParams]);

  const getFolders = useCallback(async () => {
    if (!subFolderView) {
      await dispatch(clearFolder());
      await dispatch(clearFolders());
      await dispatch(
        listFolders({
          activeDivisionId,
          paid,
          ...params,
        })
      );
      handleBreadCrumbs(ROOT);
    } else {
      await dispatch(clearFolder());
      await dispatch(
        listFolders({
          activeDivisionId,
          paid,
          ...params,
        })
      );
      await dispatch(
        getFolder({
          folderId,
          activeDivisionId,
          paid,
        })
      );
    }
  }, [folderId, activeDivisionId, paid]);

  useEffect(() => {
    getFolders();
    return () => {};
  }, [dispatch, folderId]);

  useEffect(() => {
    if (subFolderView && Object.keys(foundFolder).length) {
      handleBreadCrumbs(foundFolder);
    }
  }, [foundFolder]);

  const arrangeLineage = useMemo(() => {
    if (folders && folders.length) {
      setLocalState({
        ...localState,
        lineage: [...folders],
      });
    }
  }, [folders, localState.breadCrumbs]);

  const getBreadCrumbs = useCallback(
    (current, data = [], result = [current]) => {
      if (current?._id === "root") {
        setLocalState({ ...localState, breadCrumbs: [current] });
        return;
      }
      if (current?.depth === 0) {
        setLocalState({
          ...localState,
          breadCrumbs: [ROOT, ..._.reverse(result)],
        });
        return;
      }
      if (current?.parentFolder) {
        const match = data.find((item) => current.parentFolder === item._id);
        result.push(match);
        getBreadCrumbs(match, data, result);
        return;
      }
      setLocalState({
        ...localState,
        breadCrumbs: [ROOT, ..._.reverse(result)],
      });
    },
    [folders, folderId, foundFolder, localState]
  );

  const handleBreadCrumbs = useCallback(
    async (currentFolder) => {
      await getBreadCrumbs(currentFolder, folders, [currentFolder]);
    },
    [folders, foundFolder, localState]
  );

  const handleBackClick = useCallback(() => {
    if (foundFolder.parentFolder) {
      const parent = folders.find(
        (item) => item._id === foundFolder.parentFolder
      );
      history.push(`/foldersTemplates/${foundFolder.parentFolder}`);
    } else {
      history.push(`/foldersTemplates`);
    }
  }, [folderId, foundFolder, localState]);

  const onDelete = useCallback(async () => {
    const { _id: id } = localState.folderToDelete;
    await dispatch(
      deleteFolderById({
        id,
        activeDivisionId,
        paid,
      })
    );
    setLocalState({
      ...localState,
      showDeleteDialog: false,
      deleteMessage: "",
      folderToDelete: null,
    });
    await dispatch(clearFolders());
    await dispatch(
      listFolders({
        activeDivisionId,
        paid,
        ...params,
      })
    );
    await getFolders();
  }, [dispatch, localState.folderToDelete, activeDivisionId, paid]);

  const deleteFolder = useCallback(
    async (data) => {
      const { subFolders: hasFolders } = await api.getFolderById(data._id, {
        activeDivisionId,
        paid,
      });
      let message = "";
      if (hasFolders.length) {
        // eslint-disable-next-line max-len
        message = `'${data.name.toUpperCase()}' has associated subfolders. If deleted they will be moved under root folder.`;
      } else {
        message = `'${data.name.toUpperCase()}'`;
      }
      setLocalState({
        ...localState,
        folderToDelete: data,
        showDeleteDialog: true,
        deleteMessage: message,
      });
    },
    [dispatch, localState.folderToDelete]
  );

  const renameFolder = async (data) => {
    setLocalState({
      ...localState,
      openForm: true,
      folderToEdit: data,
    });
  };

  const saveFolder = useCallback(
    async (data) => {
      const { name } = data;
      if (name.length > 40) {
        dispatch(
          notify({
            type: "error",
            message: "Folder name should be under 40 characters!",
          })
        );
        return;
      }
      if (data._id) {
        //In case of edit
        const { _id, name } = data;
        await dispatch(
          updateFolder({
            _id,
            name,
            paid,
            activeDivisionId,
          })
        );
      } else {
        const { name } = data;
        const body = {
          name,
          parentFolder: folderId,
          paid,
        };
        if (!folderId) {
          delete body.parentFolder;
        }
        await dispatch(
          createFolder(body, {
            activeDivisionId,
          })
        );
      }
      setLocalState({
        ...localState,
        folderToEdit: null,
        openForm: false,
      });
      await dispatch(clearFolders());
      await dispatch(
        listFolders({
          activeDivisionId,
          paid,
          ...params,
        })
      );
      await getFolders();
    },
    [dispatch, folderId, paid, activeDivisionId]
  );
  const onOpenMoveDialog = (template) => {
    setLocalState({
      ...localState,
      templateToMove: template,
    });
  };
  const [masterTemplateToDelete, setMasterTemplateToDelete] = useState();
  const onOpenDeleteDialog = useCallback(
    (masterTemplate) => setMasterTemplateToDelete(masterTemplate),
    [setMasterTemplateToDelete]
  );
  const onCloseDeleteDialog = useCallback(
    () => setMasterTemplateToDelete(null),
    [setMasterTemplateToDelete]
  );

  const moveTemplate = async (folder) => {
    await dispatch(
      moveTemplateToFolder({
        folder,
        template: localState.templateToMove,
        paid,
        activeDivisionId,
      })
    );
    hasMoved(!moved);
    dispatch(setParams({}, { overwrite: true }));
    await getFolders();
    setLocalState({
      ...localState,
      templateToMove: null,
    });
  };

  const onDeleteTemplate = useCallback(async () => {
    await dispatch(deleteMasterTemplate(masterTemplateToDelete));
    await getFolders();
    await dispatch(
      listFolders({
        activeDivisionId,
        paid,
        ...params,
      })
    );
    onCloseDeleteDialog();
  }, [dispatch, onCloseDeleteDialog, masterTemplateToDelete]);

  const onSearchFolderChange = (event, value) => {
    if (value) {
      history.push(`/foldersTemplates/${value._id}`);
    }
  };

  return (
    <Page
      p={2}
      screenSized={!isSmallScreen}
      style={{
        overflow: "auto",
      }}
    >
      <Grid container>
        <Grid item xs={8} md={8}>
          <Box className={classes.customBox}>
            <Breadcrumbs
              className={{
                root: classes.title,
              }}
            >
              {localState.breadCrumbs.map((item, idx) =>
                idx === localState.breadCrumbs.length - 1 ? (
                  <Typography key={item?._id}>
                    {item.name?.toUpperCase()}
                  </Typography>
                ) : (
                  <Link
                    to={
                      item?._id === "root"
                        ? `/foldersTemplates`
                        : `/foldersTemplates/${item?._id}`
                    }
                    className={classes.link}
                    data-test-id={`folder-breadcrumbs-${item?._id}`}
                    key={item?._id}
                  >
                    {item?.name.toUpperCase()}
                  </Link>
                )
              )}
            </Breadcrumbs>
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.customBox}>
            <Autocomplete
              id="fodler-search-box"
              options={localState.lineage}
              getOptionLabel={(option) => option.name}
              onChange={onSearchFolderChange}
              fullWidth={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Search Folder"
                  variant="outlined"
                  size="small"
                />
              )}
              ListboxProps={{
                style: {
                  maxHeight: 400,
                  fontSize: 14,
                },
              }}
              noOptionsText={t("No FoldersTemplates Found!")}
              renderOption={(option) => {
                return (
                  <Typography
                    id={`dropdown-item-test-id-${option._id}`}
                    style={{
                      marginLeft: 10 * option.depth,
                    }}
                  >
                    {option.name}
                  </Typography>
                );
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.customBox}>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<AddBox />}
              onClick={() =>
                setLocalState({
                  ...localState,
                  folderToEdit: null,
                  openForm: true,
                })
              }
            >
              {t("Create Folder")}
            </Button>
          </Box>
        </Grid>
      </Grid>

      <DisplayFolders
        folders={
          !subFolderView
            ? folders?.filter((item) => !item.parentFolder || false)
            : subFolders
        }
        onRename={renameFolder}
        onDelete={deleteFolder}
      />

      <Grid container>
        <Grid item>
          <Box className={classes.customBox}>
            {subFolderView && (
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<NavigateBefore />}
                onClick={handleBackClick}
              >
                {t("Back")}
              </Button>
            )}
          </Box>
        </Grid>

        <TemplateList
          key={moved}
          t={t}
          data={subFolderView ? masterTemplates : rootTemplates}
          totalCount={
            subFolderView ? folderTemplatesCounts : rootTemplatesCounts
          }
          onOpenMoveDialog={onOpenMoveDialog}
          onOpenDeleteDialog={onOpenDeleteDialog}
          folderName={!subFolderView ? "ROOT" : foundFolder.name?.toUpperCase()}
          subFolderView={subFolderView}
          folderId={folderId}
        />
      </Grid>

      <DeleteDialog
        open={Boolean(masterTemplateToDelete)}
        onClose={onCloseDeleteDialog}
        onDelete={onDeleteTemplate}
        name={masterTemplateToDelete?.name}
        resource={t("Template")}
      />

      <FormDialog
        open={localState.openForm}
        resource="Folder"
        onClose={() =>
          setLocalState({
            ...localState,
            folderToEdit: null,
            openForm: false,
          })
        }
        onSave={saveFolder}
        fields={formFields}
        data={localState.folderToEdit}
      />

      <DeleteDialog
        open={localState.showDeleteDialog}
        onClose={() =>
          setLocalState({
            ...localState,
            showDeleteDialog: false,
            deleteMessage: "",
            folderToDelete: null,
          })
        }
        onDelete={onDelete}
        name={localState.deleteMessage}
        resource={t("Folder")}
      />

      <MoveTemplateDialog
        t={t}
        open={Boolean(localState.templateToMove)}
        _onClose={() => {
          setLocalState({
            ...localState,
            templateToMove: null,
          });
        }}
        _onMove={moveTemplate}
        templateName={
          localState.templateToMove ? localState.templateToMove.name : ""
        }
        data={localState.lineage}
      />
    </Page>
  );
};
export default memo(ListFolders);
