import React, { memo } from "react";
import { Button, DialogContentText, DialogTitle } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export const CustomDeleteDialogTitle = memo(({ suppressionTab }) => {
  const { t } = useTranslation();
  return (
    <DialogTitle>
      {t(`Remove Suppression ${suppressionTab}`)}
    </DialogTitle>
  );
});

export const CustomDeleteDialogContentText = memo(({ suppressionToDelete }) => {
  const { t } = useTranslation();
  const fromField = suppressionToDelete.shortcode
    || suppressionToDelete.domainAddress
    || suppressionToDelete.callerId;
  return (
    <DialogContentText>
      {t("Are you sure you would like to remove this record from the suppression list:")}
      <ul>
        <li>{t("Recipient:")} {suppressionToDelete.to.toLowerCase()}</li>
        <li>{t("From:")} {fromField.toLowerCase()}</li>
      </ul>
    </DialogContentText>
  );
});

export const CustomDeleteConfirmButton = memo(({ onDelete }) => {
  const { t } = useTranslation();
  return (
    <Button
      onClick={onDelete}
      color="secondary"
      data-test-id="delete-dialog-delete-button"
    >
      {t("Remove from Suppression List")}
    </Button>
  );
});
