import React, { memo, useState, useCallback, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { listCampaigns, clearCampaigns } from "../actions/campaigns";
import { Page } from "../components";
import {
  Paper,
  Grid,
  Button,
  Box,
  Typography,
  Dialog,
  List,
  ListItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  format,
  subMonths,
  addMonths,
  startOfMonth,
  getDay,
  getDaysInMonth,
  setDay,
  getYear,
  getMonth,
  getDate,
  parseISO,
  setDate,
} from "date-fns";
import { NavigateNext, NavigateBefore } from "../icons";
import _ from "lodash";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    overflow: "auto",
    flex: 1,
  },
  day: {
    height: 115,
    borderRadius: 15,
    padding: 10,
    backgroundColor: "#ebf2f7",
    opacity: 0.4,
  },
  selectableDay: {
    opacity: 1,
    display: "flex",
  },
  currentDate: {
    backgroundColor: "white",
    boxShadow: "2px 2px 4px #eee",
  },
  navButton: {
    fontSize: "large",
  },
  events: {
    marginLeft: 8,
    overflow: "hidden",
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
}));

function getDayOfWeek(date) {
  const day = getDay(date);
  return day === 0 ? 6 : day - 1;
}

function getCalendar(dateToView, currentDate, campaigns = []) {
  let counter = 1;
  const indexOfFirstDay = getDayOfWeek(startOfMonth(dateToView));
  const indexOfDayAfterLastDay = indexOfFirstDay + getDaysInMonth(dateToView);

  const daysWithDates = Array(6 * 7)
    .fill(0)
    .map((day, index) => {
      if (index >= indexOfFirstDay && index < indexOfDayAfterLastDay) {
        return counter++;
      } else {
        return 0;
      }
    });

  const weeks = _.times(6, () => {
    const week = daysWithDates.splice(0, 7);
    if (week.every((day, i, week) => day === week[0])) {
      return undefined;
    }
    const weekWithDays = week.map((day, i) => {
      if (day !== 0) {
        const isCurrentDate =
          getYear(currentDate) === getYear(dateToView) &&
          getMonth(currentDate) === getMonth(dateToView) &&
          getDate(currentDate) === day;

        const events = campaigns?.data.filter((campaign) => {
          const scheduleDate = parseISO(campaign.schedule);

          // TODO: Pending status required in calendar
          return getDate(scheduleDate) === day && campaign.status !== "PENDING";
        });

        return {
          dayOfMonth: day,
          dayOfWeek: format(setDay(new Date(), i + 1), "iii"),
          isCurrentDate,
          events,
        };
      }
      return day;
    });
    return weekWithDays;
  }).filter((week) => week !== undefined);
  return weeks;
}

function CalendarDay({ day, onClick }) {
  const classes = useStyles();
  if (day !== 0) {
    return (
      <Paper
        elevation={0}
        className={[
          day.isCurrentDate && classes.currentDate,
          classes.selectableDay,
          classes.day,
        ].join(" ")}
        onClick={onClick}
      >
        <div>
          <Typography style={{ fontWeight: "bold" }} color="textSecondary">
            {day.dayOfWeek}
          </Typography>
          <Typography variant="h5">{day.dayOfMonth}</Typography>
        </div>
        <div className={classes.events}>
          {day?.events?.map((event) => (
            <div key={event._id}>{event.name}</div>
          ))}
        </div>
      </Paper>
    );
  } else {
    return <Paper elevation={0} className={classes.day} />;
  }
}

function DayView({ onClose, dateToView, day }) {
  const { t } = useTranslation();
  const history = useHistory();

  const onCampaignClick = useCallback(
    (campaign) => {
      history.push(`/campaigns/${campaign._id}`);
    },
    [history]
  );

  const onCreateCampaign = useCallback(() => {
    history.push(`/campaigns/create`);
  }, [history]);

  if (day !== undefined) {
    return (
      <Dialog open={true} onClose={onClose} maxWidth={false}>
        <Box p={2}>
          <Grid container item xs direction="column">
            <Grid container item xs justify="space-between">
              <Button
                variant="contained"
                color="primary"
                onClick={onCreateCampaign}
              >
                {t("Create campaign")}
              </Button>
              <Typography variant="h4" color="textSecondary">
                {format(setDate(dateToView, day.dayOfMonth), "MMMM dd, yyyy")}
              </Typography>
            </Grid>
            <Grid item xs>
              <List style={{ width: 550 }}>
                {day?.events
                  .sort((a, b) => {
                    const keyA = new Date(a.schedule);
                    const keyB = new Date(b.schedule);

                    if (keyA < keyB) return -1;
                    if (keyA > keyB) return 1;
                    return 0;
                  })
                  .map((event) => {
                    return (
                      <ListItem
                        onClick={() => onCampaignClick(event)}
                        button
                        key={event._id}
                        divider
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      > <Typography style={{ fontWeight: "bold" }}>
                          {event.name}
                        </Typography>
                        <Typography color="textSecondary">
                          {format(new Date(event.schedule), "ppp")}
                        </Typography>
                      </ListItem>
                    );
                  })}
              </List>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
    );
  }
  return <></>;
}

function CalendarPage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [dayObject, setDayObject] = useState(undefined);

  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );

  const currentDate = new Date();
  const [nextDate, setNextDate] = useState(currentDate);
  const [dateToView, setDateToView] = useState(currentDate);

  const moveToPreviousMonth = useCallback(() => {
    setNextDate(subMonths(dateToView, 1));
  }, [dateToView, setNextDate]);

  const moveToNextMonth = useCallback(() => {
    setNextDate(addMonths(dateToView, 1));
  }, [dateToView, setNextDate]);

  useEffect(() => {
    async function setCalendarPageData() {
      await dispatch(
        listCampaigns({
          activeDivisionId,
          fromDate: startOfMonth(nextDate).getTime(),
          toDate: startOfMonth(addMonths(nextDate, 1)).getTime(),
          schedule: true,
          noPagination: true,
        })
      );
      setDateToView(nextDate);
    }
    setCalendarPageData();
  }, [dispatch, nextDate, setDateToView, activeDivisionId]);

  // clean up global data and params when the user leaves the page
  useEffect(
    // return a function which runs at component unmount
    () => () => dispatch(clearCampaigns()),
    [dispatch]
  );

  const campaigns = useSelector((state) => state.campaigns);

  const calendarTitle = useMemo(() => {
    return format(dateToView, "MMMM yyyy");
  }, [dateToView]);

  const getMonthsName = useCallback(
    (monthsFunction) => {
      return format(monthsFunction(dateToView, 1), "MMMM");
    },
    [dateToView]
  );

  const previousMonthsName = useMemo(() => {
    return getMonthsName(subMonths);
  }, [getMonthsName]);

  const nextMonthsName = useMemo(() => {
    return getMonthsName(addMonths);
  }, [getMonthsName]);

  return (
    <Page title={t("Calendar page")}>
      <Box p={3} className={classes.root}>
        <Grid container spacing={2} direction="column">
          <Grid item xs>
            <Grid container justify="space-between">
              <Button
                className={classes.navButton}
                onClick={moveToPreviousMonth}
              >
                <NavigateBefore color="primary" fontSize="large" />
                {previousMonthsName}
              </Button>
              <Typography variant="h3">{calendarTitle}</Typography>
              <Button className={classes.navButton} onClick={moveToNextMonth}>
                {nextMonthsName}
                <NavigateNext color="primary" fontSize="large" />
              </Button>
            </Grid>
          </Grid>
          {getCalendar(dateToView, currentDate, campaigns).map(
            (week, weekIndex) => (
              <Grid key={`${dateToView}_${weekIndex}`} item xs>
                <Grid container spacing={2}>
                  {week.map((day, dayIndex) => (
                    <Grid
                      key={`${dateToView}_${weekIndex}_${dayIndex}`}
                      xs
                      item
                    >
                      <CalendarDay
                        day={day}
                        onClick={() => setDayObject(day)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )
          )}
        </Grid>
      </Box>
      <DayView
        onClose={() => setDayObject(undefined)}
        day={dayObject}
        dateToView={dateToView}
      />
    </Page>
  );
}

export default memo(CalendarPage);
