import * as api from "../api/dnd";
import { notify } from "./notification";

export const LIST_DND = "LIST_DND";
export const CLEAR_DND = "CLEAR_DND";
export const GET_DND = "GET_DND";
export const SAVE_DND = "SAVE_DND";

export function clearDnd() {
  return { type: CLEAR_DND };
}

export function saveDnd(dnd) {
  return async (dispatch, getState) => {
    try {
      await api.saveDnd(dnd);
      dispatch(
        notify({
          type: "success",
          message: "DND set successfully",
        })
      );
    } catch (err) {
      const data = err.response?.data;
      const message = data ? data.details[0]?.message : "";
      dispatch({
        type: GET_DND,
        dndData: {
          errors: {
            startAt: message.indexOf("start") === -1 ? false : true,
            endAt: message.indexOf("end") === -1 ? false : true,
          },
        },
      });
      throw err;
    }
    dispatch(clearDnd());
  };
}

export function deleteDnd(paid) {
  return async (dispatch, getState) => {
    try {
      await api.deleteDnd(paid);
      dispatch(clearDnd());
      dispatch(
        notify({
          type: "success",
          message: "DND removed successfully!",
        })
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err);
      dispatch(
        notify({
          type: "error",
          message: "something broke",
        })
      );
    }
  };
}

export function updateDnd(dnd) {
  return async (dispatch, getState) => {
    try {
      await api.updateDnd(dnd);
      dispatch(
        notify({
          type: "success",
          message: "DND set successfully",
        })
      );
    } catch (err) {
      const data = err.response?.data;
      const message = data ? data.details[0]?.message : "";
      dispatch({
        type: GET_DND,
        dndData: {
          errors: {
            startAt: message.indexOf("start") === -1 ? false : true,
            endAt: message.indexOf("end") === -1 ? false : true,
          },
        },
      });
      throw err;
    }
    dispatch(clearDnd());
  };
}

export function getDnd(paid) {
  return async (dispatch, getState) => {
    const dndData = await api.listDnd({ paid: paid });
    dispatch({
      type: GET_DND,
      dndData: dndData,
    });
  };
}
