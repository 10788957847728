import React, { useState, useCallback, memo } from "react";
import {
  KeyboardDateTimePicker,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from "@material-ui/pickers";
import classNames from "classnames";
import { Today } from "../icons";
import { isValid } from "date-fns";

function CustomDateTimePicker({
  defaultValue,
  displayFormat,
  onAccept,
  date = true,
  time = false,
  size = "small",
  testId,
  onChange,
  disabled = false,
  ...rest
}) {
  const [value, setValue] = useState(defaultValue);
  const [isOpen, setOpen] = useState(false);
  const onOpen = useCallback(() => setOpen(true), [setOpen]);
  const onClose = useCallback(() => setOpen(false), [setOpen]);
  const SelectedPicker =
    date && time
      ? KeyboardDateTimePicker
      : time
      ? KeyboardTimePicker
      : KeyboardDatePicker;

  const pickerId =
    date && time ? "date-time-picker" : time ? "time-picker" : "date-picker";

  return (
    <SelectedPicker
      {...rest}
      onAccept={onAccept}
      onOpen={onOpen}
      onClose={onClose}
      autoOk
      value={value}
      size={size}
      onChange={(date) => {
        isValid(date) && setValue(date);
        isValid(date) && onAccept(date);
      }}
      inputVariant="outlined"
      variant="inline"
      invalidDateMessage=""
      format={displayFormat || "MM/dd/yyyy hh:mm a"}
      InputProps={{
        className: classNames({ "Mui-focused": isOpen }),
      }}
      KeyboardButtonProps={{ size: "small", color: "primary" }}
      keyboardIcon={<Today />}
      data-test-id={`${testId}-${pickerId}`}
      disabled={disabled}
    />
  );
}

export default memo(CustomDateTimePicker);
