import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "../";

function ViewListDialog({ onClose, open, resource, children, ...rest }) {
  const { t } = useTranslation();

  return (
    <Dialog {...rest} open={open} onClose={onClose} fullScreen>
      <DialogTitle>{t("View {{resource}}", { resource })}</DialogTitle>
      <DialogContent>
        <DialogContentText>{children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} data-test-id="view-list-dialog-close-button">
          {t("close")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(ViewListDialog);
