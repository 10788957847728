import React, {
  useMemo,
  useCallback,
  useEffect,
  useImperativeHandle,
  memo,
  forwardRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { batch, useDispatch, useSelector } from "react-redux";
import { sub, endOfDay, startOfDay, format } from "date-fns";
import { makeStyles } from "@material-ui/core/styles";
import { SmartTable } from "../../../components";
import {
  listRecipientLists,
  clearRecipientLists,
  getDippingStatus,
} from "../../../actions/recipientLists";
import { setParams } from "../../../actions/params";
import Filters from "./Filters";
import { useTableActions } from "../../../hooks/useTableActions";

const getColumns = ({ t, extraColumns = [] }) => [
  {
    name: "name",
    label: t("Name"),
    sortable: true,
    width: 1,
    testId: "recipientList-name",
  },
  {
    name: "createdByName",
    label: t("Created By"),
    sortable: true,
    testId: "recipientList-createdBy",
  },
  {
    name: "createdDate",
    getter: (list) => format(new Date(list.createdDate), "MM/dd/yyyy"),
    label: t("Created At"),
    sortable: true,
    width: 1,
    testId: "recipientList-createdAt",
    width: 0.8,
  },
  // {
  //   name: "permission",
  //   label: t("Permission"),
  //   sortable: true,
  //   testId: "recipientList-permission",
  // },
  {
    name: "emailCount",
    label: t("Email"),
    sortable: true,
    width: 0.5,
    align: "center",
    testId: "recipientList-emailCount",
  },
  {
    name: "smsCount",
    label: t("SMS"),
    sortable: true,
    width: 0.5,
    align: "center",
    testId: "recipientList-smsCount",
  },
  {
    name: "voiceCount",
    label: t("Voice"),
    sortable: true,
    width: 0.5,
    align: "center",
    testId: "recipientList-voiceCount",
  },
  {
    name: "ttyCount",
    label: t("TTY"),
    sortable: true,
    width: 0.5,
    align: "center",
    testId: "recipientList-ttyCount",
  },
  {
    name: "customCount",
    label: t("Custom"),
    sortable: true,
    width: 0.5,
    align: "center",
    testId: "recipientList-customCount",
  },
  {
    name: "dipConverted",
    label: t("Flipped"),
    sortable: true,
    width: 0.5,
    align: "center",
    testId: "recipientList-converted",
  },
  ...extraColumns,
];

function getParams(queryParams, activeDivisionId, activeCompanyId, status) {
  // pick the usable params, do not include extra params that are not used by this page
  const {
    search,
    fromDate,
    toDate,
    page,
    itemsPerPage,
    orderBy,
    order,
    permission,
  } = queryParams;

  // add default values to the usable params
  // the end of the current day to include the whole day as the user would expect
  const defaultToDate = endOfDay(Date.now()).getTime();
  // the start of the day to include the whole day as the user would expect
  const defaultFromDate = startOfDay(
    sub(defaultToDate, { months: 12 })
  ).getTime();

  return {
    search: search ?? "",
    permission,
    fromDate: fromDate ?? defaultFromDate,
    toDate: toDate ?? defaultToDate,
    page: page ?? 0,
    itemsPerPage: itemsPerPage ?? 20,
    orderBy: orderBy ?? "createdDate",
    order: order ?? -1,
    activeDivisionId,
    companyId: activeCompanyId,
    status,
  };
}

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 1190,
  },
  smallCell: {
    minWidth: 110,
    maxWidth: 150,
  },
  noWrap: {
    whiteSpace: "nowrap",
  },
}));

function RecipientListsPageBase(
  { extraColumns, onlyStatus, onClickCreate, disableTable, isCampaign },
  ref
) {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: recipientList, totalCount, dipStatus } = useSelector((state) =>
    state.recipientLists ? state.recipientLists : { totalCount: 0 }
  );

  const queryParams = useSelector((state) => state.params);
  const activeDivisionId = useSelector(
    (state) => state.storage.activeDivisionId
  );
  const activeCompanyId = useSelector((state) => state.storage.companyId);
  const {
    onItemsPerPageChange,
    onPageChange,
    onSearchChange,
    onSortChange,
  } = useTableActions();

  // fill in missing query params with default values and the active division id
  // the default values are not persisted in the query string for a better user experience

  const [params, setStateParams] = useState(
    getParams(queryParams, activeDivisionId, activeCompanyId, onlyStatus)
  );

  useEffect(() => {
    setStateParams(
      getParams(queryParams, activeDivisionId, activeCompanyId, onlyStatus)
    );
  }, [queryParams, activeDivisionId, activeCompanyId, onlyStatus]);

  // clean up global data and params when the user leaves the page
  useEffect(
    // return a function which runs at component unmount
    () => () => dispatch(clearRecipientLists()),
    [dispatch]
  );

  // memoize the column getter for a perf boost
  const columns = useMemo(() => getColumns({ t, extraColumns }), [
    t,
    extraColumns,
  ]);

  // refetch the recipient lists whenever the params change
  // Introduce long polling
  useEffect(() => {
    dispatch(listRecipientLists(params));
  }, [dispatch, params]);

  useEffect(() => {
    let intval = setInterval(() => {
      dispatch(listRecipientLists(params));
    }, 32000);

    return () => {
      clearInterval(intval);
    };
  }, [params]);

  useEffect(() => {
    const fetchDipStatus = () => {
      const recipientListIds = recipientList
        ? recipientList.map((list) => list._id)
        : [];
      batch(() => {
        recipientListIds.forEach((list) => {
          dispatch(getDippingStatus(list));
        });
      });
    };

    fetchDipStatus();
  }, [dispatch, recipientList]);

  useImperativeHandle(ref, () => ({
    listRecipientLists: () => dispatch(listRecipientLists(params)),
  }));

  const onPermissionChange = useCallback(
    (ev) => dispatch(setParams({ permission: ev.target.value, page: 0 })),
    [dispatch]
  );

  const onFromDateChange = useCallback(
    (fromDate) =>
      dispatch(setParams({ fromDate: fromDate.getTime(), page: 0 })),
    [dispatch]
  );

  const onToDateChange = useCallback(
    (toDate) => dispatch(setParams({ toDate: toDate.getTime(), page: 0 })),
    [dispatch]
  );

  const data = recipientList
    ? recipientList.map((list) => {
        if (dipStatus && dipStatus[list._id])
          return {
            ...list,
            dip: dipStatus[list._id],
            dipConverted: dipStatus[list._id].converted
              ? dipStatus[list._id].converted
              : "-",
          };
        return list;
      })
    : [];

  return (
    <SmartTable
      filters={
        <Filters
          params={params}
          isCampaign={isCampaign}
          onSearchChange={onSearchChange}
          onPermissionChange={onPermissionChange}
          onFromDateChange={onFromDateChange}
          onToDateChange={onToDateChange}
          onClickCreate={onClickCreate}
        />
      }
      className={classes.table}
      columns={columns}
      data={data}
      totalCount={totalCount}
      notFoundMessage={t("No recipient lists found for your filters")}
      orderBy={params.orderBy}
      order={params.order}
      page={params.page}
      itemsPerPage={params.itemsPerPage}
      onSortChange={onSortChange}
      onPageChange={onPageChange}
      onItemsPerPageChange={onItemsPerPageChange}
      testId="recipientList"
      style={{ display: disableTable ? "none" : "table" }}
    />
  );
}

export default memo(forwardRef(RecipientListsPageBase));
