import React, { useCallback, useState, useMemo, memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  TextField,
  Button,
  Tooltip,
  Autocomplete,
} from "../../../../components";
import { Info as InfoIcon } from "../../../../icons";
import { sendTestMessage } from "../../../../actions/masterTemplate";
import { notify } from "../../../../actions/notification";
import RecipientFieldSelector from "../RecipientFieldSelector";
import "../style.css";
import { CKEditor } from "ckeditor4-react";
import CustomFieldsDialog from "../../../../components/CustomFieldsDialog";
import { textToHTMLEntities } from "../../../../utils/convertTextToHTMLEntities";
import {
  getEmailPlaceHolders,
  getPlaceHolders,
} from "../../../Campaigns/Single/FillData";

const useStyles = makeStyles({
  testButton: {
    height: 40,
  },
  flexDivider: {
    marginLeft: "auto",
  },
});

function EmailTemplateSection({
  emailTemplate = {},
  errors = {},
  onEmailFieldChange,
  readOnly,
  isEmailChecked,
}) {
  const getEmailDomains = ({ domainAddress = "" }) =>
    domainAddress ? domainAddress.split(",") : [];
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const readOnlyProps = useMemo(() => ({ readOnly }), [readOnly]);
  const [testEmail, setTestEmail] = useState("");
  let {
    vendorConfig: {
      createCustomerTemplateData,
      urlShortener = false,
      UNSUBSCRIBE = false,
      defaultFromName = undefined,
    },
    emailConfig,
  } = useSelector((state) => state.eonsConfig);
  const emailDomain = useMemo(() => getEmailDomains(emailConfig), [
    emailConfig,
  ]);
  const [instanceCKE, setInstanceCKE] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const updateTimer = React.useRef(null);
  const {
    fromEmail = "",
    subjectEmail = "",
    fromName = defaultFromName,
    _id: templateId = "",
  } = emailTemplate;
  const [template, setTemplate] = useState("");
  const canUseCustomerData = createCustomerTemplateData;
  const [emailTargetFieldsSetup, setEmailTargetFieldsSetup] = useState({});

  const [
    emailSubjectTargetFieldsSetup,
    setEmailSubjectTargetFieldsSetup,
  ] = useState({});

  const handleEmailChange = (event, templateValue, freeForm) => {
    const recipientHeaderValue = event.target.value;
    setEmailTargetFieldsSetup({
      ...emailTargetFieldsSetup,
      [templateValue]: {
        freeForm,
        value: recipientHeaderValue,
      },
    });
  };

  const handleEmailSubjectChange = (event, templateValue, freeForm) => {
    const recipientHeaderValue = event.target.value;
    setEmailSubjectTargetFieldsSetup({
      ...emailSubjectTargetFieldsSetup,
      [templateValue]: {
        freeForm,
        value: recipientHeaderValue,
      },
    });
  };

  const [isUrlShortner, setIsUrlShortner] = useState(false);
  const onAddRecipientField = useCallback(
    (header) => {
      instanceCKE.editor.insertText(`<%= ${header} %> `);
    },
    [instanceCKE]
  );
  const onTestEmailChange = useCallback((ev) => setTestEmail(ev.target.value), [
    setTestEmail,
  ]);
  const emailPlaceHolders = useMemo(
    () => getEmailPlaceHolders(emailTemplate.template),
    [emailTemplate.template]
  );
  const [openModal, setOpenModal] = useState(false);
  const emailSubjectPlaceHolders = useMemo(
    () => getPlaceHolders(subjectEmail),
    [subjectEmail]
  );

  useEffect(() => {
    let instanceValue = "";

    setTimeout(() => {
      setIsLoading(true);
      if (instanceCKE) {
        //ensure template value is finalized before set CKE
        instanceValue = instanceCKE.editor.getData().trim();
        if (!instanceValue || instanceValue === "") {
          instanceCKE.editor.setData(emailTemplate.template || "");
        }
      }
    }, 500);

    updateTimer.current = setInterval(() => {
      if (instanceCKE && isEmailChecked) {
        const editable = instanceCKE.editor.editable();

        editable.attachListener(editable, "dblclick", function (evt) {
          setIsUrlShortner(true);
        });

        instanceValue = instanceCKE.editor.getData().trim();
        if (instanceValue !== template) {
          if (!template) {
            onEmailFieldChange(instanceCKE, true);
            setTemplate(instanceValue);
            return;
          }
          onEmailFieldChange(instanceCKE);
          setTemplate(instanceValue);
        }
      }
    }, 2000);

    return () => {
      if (updateTimer && updateTimer.current) {
        clearInterval(updateTimer.current);
      }
    };
  }, [instanceCKE, emailTemplate]);

  const handleOnInstanceReady = useCallback(
    (instance) => {
      setInstanceCKE(instance);
      // Added to replace encoded item to original.
      // htmlEncodeOutput=false can introduce security risks
      instance.editor.dataProcessor.htmlFilter.addRules({
        text: function (text, node) {
          return text.replace("&lt;^", "<^").replace("^&gt;", "^>");
        },
      });
    },
    [template]
  );

  const onSendTestEmail = useCallback(async () => {
    setOpenModal(false);
    let testTemplate = instanceCKE.editor.getData().trim();
    let testSubject = subjectEmail;
    const tempEmailPlaceHolders = getEmailPlaceHolders(
      instanceCKE.editor.getData().trim()
    );
    for (let x = 0; x < tempEmailPlaceHolders.length; x++) {
      const replacementText =
        emailTargetFieldsSetup[tempEmailPlaceHolders[x]]?.value;
      if (replacementText) {
        testTemplate = testTemplate.replaceAll(
          new RegExp(`&lt;%=\\s*${tempEmailPlaceHolders[x]}\\s*%&gt;`, "gi"),
          replacementText
        );
      }
    }
    for (let x = 0; x < emailSubjectPlaceHolders.length; x++) {
      const replacementText =
        emailSubjectTargetFieldsSetup[emailSubjectPlaceHolders[x]]?.value;
      if (replacementText) {
        testSubject = testSubject.replaceAll(
          new RegExp(`<%=\\s*${emailSubjectPlaceHolders[x]}\\s*%>`, "gi"),
          replacementText
        );
      }
    }
    try {
      await dispatch(
        sendTestMessage({
          channelType: "email",
          data: {
            from: fromEmail || "eons@test.com",
            to: testEmail,
            testMessage: testTemplate,
            friendlyName: fromName || defaultFromName,
            subject: testSubject || "Test Subject",
          },
        })
      );
    } catch (err) {
      let errorMessage = "";
      if (!testTemplate) {
        errorMessage = "You must provide the text for the email body. ";
      } else if (!testEmail) {
        errorMessage = "You must provide a valid test email address.";
      } else {
        errorMessage = err;
      }

      dispatch(
        notify({
          type: "error",
          message: t(errorMessage),
        })
      );
      throw err;
    }
  }, [
    dispatch,
    fromEmail,
    fromName,
    testEmail,
    template,
    subjectEmail,
    emailTargetFieldsSetup,
    emailSubjectTargetFieldsSetup,
    t,
  ]);

  const renderAutoComplete = (params) => {
    return (
      <TextField
        data-test-id="master-template-from-email-field"
        variant="outlined"
        error={Boolean(errors["emailTemplate.fromEmail"])}
        {...params}
        size="small"
        label={t("From Email Address")}
        value={fromEmail}
        autoComplete="off"
        onChange={onEmailFieldChange}
        name="fromEmail"
      />
    );
  };
  const handleFromEmailChange = (t) => {
    const value =
      t && t.target && t.target.id && t.target.id.includes("fromEmail")
        ? t.target.innerHTML
        : undefined;
    onEmailFieldChange({
      target: {
        name: "fromEmail",
        value,
      },
    });
  };

  const handleFromNameChange = (t) => {
    let value = t.currentTarget.value;

    onEmailFieldChange({
      target: {
        name: "fromName",
        value,
      },
    });
  };

  return (
    isLoading && (
      <Grid
        container
        direction="column"
        spacing={2}
        className={"emailTemplate"}
      >
        <Grid container spacing={2}>
          {urlShortener && (
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Button
                disabled={readOnly}
                data-test-id="master-template-send-url-shortner-btn"
                variant="contained"
                className={classes.testButton}
                onClick={() => {
                  const replacedText = textToHTMLEntities(
                    instanceCKE.editor.getSelection().getSelectedText()
                  );
                  let data = instanceCKE.editor.getData().trim();
                  if (replacedText) {
                    data = data.replace(
                      replacedText,
                      "<^URL_SHORTNER " + replacedText + "^>"
                    );
                    instanceCKE.editor.setData(data);
                  }
                }}
              >
                {t("URL Shortener")}
              </Button>
            </Grid>
          )}
          {UNSUBSCRIBE && (
            <Grid item xs={12} sm={6} md={3} lg={2}>
              <Button
                disabled={readOnly}
                data-test-id="master-template-unsubscribe-placeholder-btn"
                variant="contained"
                className={classes.testButton}
                onClick={() => {
                  let data = instanceCKE.editor.getData().trim();
                  data = data + " <^MB_UNSUBSCRIBE^>";
                  instanceCKE.editor.setData(data);
                }}
              >
                {t("UNSUBSCRIBE")}
              </Button>
            </Grid>
          )}
        </Grid>
        <br />
        <Grid
          container
          direction="column"
          spacing={2}
          data-test-id="master-template-email-section"
        >
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <Autocomplete
                  id="fromEmail"
                  autoComplete={"off"}
                  freeSolo
                  name="fromEmail"
                  options={emailDomain.map((option) => option)}
                  renderInput={(params) => renderAutoComplete(params)}
                  onInputChange={handleFromEmailChange}
                  value={fromEmail}
                  inputValue={fromEmail}
                  disabled={readOnly}
                />
                {/* <TextField
                  data-test-id="master-template-from-email-field"
                  select
                  label={t("From Email Address")}
                  variant="outlined"
                  size="small"
                  fullWidth
                  name="fromEmail"
                  value={fromEmail}
                  
                  error={Boolean(errors["emailTemplate.fromEmail"])}
                  helperText={errors["emailTemplate.fromEmail"]}
                  InputProps={readOnlyProps}
                >
                  {emailDomain.map((emailAddress) => (
                    <MenuItem key={emailAddress} value={emailAddress}>
                      {emailAddress}
                    </MenuItem>
                  )) || []}
                </TextField> */}
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField
                  disabled={readOnly}
                  data-test-id="master-template-from-name-field"
                  label={t("From Name")}
                  variant="outlined"
                  size="small"
                  fullWidth
                  autoComplete="off"
                  name="fromName"
                  value={fromName}
                  onChange={handleFromNameChange}
                  error={Boolean(errors["emailTemplate.fromName"])}
                  helperText={errors["emailTemplate.fromName"]}
                  InputProps={readOnlyProps}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3} lg={2}>
                <TextField
                  disabled={readOnly}
                  data-test-id="master-template-email-subject-field"
                  label={t("Email Subject")}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  fullWidth
                  name="subjectEmail"
                  value={subjectEmail}
                  onChange={onEmailFieldChange}
                  error={Boolean(errors["emailTemplate.subjectEmail"])}
                  helperText={errors["emailTemplate.subjectEmail"]}
                  InputProps={readOnlyProps}
                  inputProps={{
                    "aria-autocomplete": "none",
                  }}
                />
              </Grid>
              {canUseCustomerData && (
                <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
                  <RecipientFieldSelector
                    data-test-id="master-template-email-variable-section"
                    disabled={readOnly}
                    onAddRecipientField={onAddRecipientField}
                  />
                </Grid>
              )}
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={2}
                className={classes.flexDivider}
              >
                <TextField
                  disabled={readOnly}
                  data-test-id="master-template-test-email-field"
                  type="email"
                  label={t("Test Email Address")}
                  autoComplete="off"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={testEmail}
                  onChange={onTestEmailChange}
                  inputProps={{
                    "aria-autocomplete": "none",
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  disabled={readOnly}
                  data-test-id="master-template-send-test-email-button"
                  variant="contained"
                  color="primary"
                  className={classes.testButton}
                  onClick={() => {
                    const tempEmailPlaceHolders = getEmailPlaceHolders(
                      instanceCKE.editor.getData().trim()
                    );
                    if (tempEmailPlaceHolders.length === 0) {
                      onSendTestEmail();
                    } else {
                      setOpenModal(true);
                    }
                  }}
                >
                  {t("Send Test")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <CustomFieldsDialog
              onClose={() => setOpenModal(false)}
              onSave={onSendTestEmail}
              emailPlaceHolders={emailPlaceHolders}
              emailSubjectPlaceHolders={emailSubjectPlaceHolders}
              handleEmailChange={handleEmailChange}
              emailTargetFieldsSetup={emailTargetFieldsSetup}
              emailSubjectTargetFieldsSetup={emailSubjectTargetFieldsSetup}
              handleEmailSubjectChange={handleEmailSubjectChange}
              open={openModal}
              errors={errors}
              classes={classes}
              type={"EMAIL"}
            />
          </Grid>
          <div data-test-id="master-template-ck-editor">
            <CKEditor
              readOnly={readOnly}
              debug={false}
              config={{
                versionCheck: false,
                allowedContent: true,
                extraAllowedContent: "*{*}",
                height: "600px"
              }}
              onInstanceReady={handleOnInstanceReady}
              name="template"
            />
          </div>
        </Grid>
        {templateId && (
          <Grid
            item
            className={classes.flexDivider}
            style={{ paddingTop: "1em" }}
            data-test-id="master-template-email-id"
          >
            <Tooltip title={templateId} placement="right" interactive>
              <InfoIcon color="disabled" style={{ height: "auto" }} />
            </Tooltip>
          </Grid>
        )}
      </Grid>
    )
  );
}

export default memo(EmailTemplateSection);
