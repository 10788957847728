import i18n from "i18next";
import * as campaignApi from "../api/campaign";
import * as templateApi from "../api/masterTemplate";
import { saveMasterTemplate } from "./masterTemplate";
import { notify } from "./notification";
import formatEscape from "../utils/formatEscape";

export const CLEAR_CAMPAIGN = "CLEAR_CAMPAIGN";
export const SET_CAMPAIGN = "SET_CAMPAIGN";
export const BUILD_CAMPAIGN = "BUILD_CAMPAIGN";

const SMS_TEMPLATE = "smsTemplate";
const EMAIL_TEMPLATE = "emailTemplate";
const VOICE_TEMPLATE = "voiceTemplate";
const TTY_TEMPLATE = "ttyTemplate";
const CUSTOM_TEMPLATE = "customTemplate";

export function stopCampaign({ _id, name }) {
  return async (dispatch, getState) => {
    if (_id) {
      const { storage } = getState();
      const { activeDivisionId } = storage;
      const params = { activeDivisionId };
      await campaignApi.stopCampaign(_id, params);
      dispatch(
        notify({
          type: "success",
          message: i18n.t("{{name}} successfully stopped", {
            name: name ?? i18n.t("campaign"),
          }),
        })
      );
    }
  };
}

export function resumeCampaign({ _id, name }) {
  return async (dispatch, getState) => {
    if (_id) {
      const { storage } = getState();
      const { activeDivisionId } = storage;
      const params = { activeDivisionId };
      await campaignApi.resumeCampaign(_id, params);
      dispatch(
        notify({
          type: "success",
          message: i18n.t("{{name}} successfully resumed", {
            name: name ?? i18n.t("campaign"),
          }),
        })
      );
    }
  };
}

export function deleteCampaign({ _id, name }, params) {
  return async (dispatch) => {
    if (_id) {
      await campaignApi.deleteCampaign(_id, params);
      dispatch(
        notify({
          type: "success",
          message: i18n.t("{{name}} successfully deleted", {
            name: name ?? i18n.t("campaign"),
          }),
        })
      );
    }
  };
}

export function getCampaign(id) {
  return async (dispatch, getState) => {
    const { campaign: existingCampaign } = getState();
    if (!id) {
      dispatch(setCampaign({}));
    } else if (existingCampaign?._id !== id) {
      const { storage } = getState();
      const { activeDivisionId } = storage;
      try {
        const campaign = await campaignApi.getCampaign(id, {
          activeDivisionId,
        });
        dispatch(setCampaign(campaign));
      } catch (err) {
        dispatch(setCampaign({ notFound: true }));
      }
    }
  };
}

export function cloneCampaign({ _id, name }, params) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;

    const clone = await campaignApi.cloneCampaign(_id, { activeDivisionId });

    dispatch(
      notify({
        type: "success",
        message: i18n.t("Successfully cloned {{name}}!", {
          name,
        }),
      })
    );

    return clone._id;
  };
}

export function saveCampaignLists() {
  return async (dispatch, getState) => {
    const { campaign, storage } = getState();
    const { _id, lists, listDetails, errors, modified, stepIdx } = campaign;

    if (!modified && 0 <= stepIdx) {
      return true;
    }

    try {
      const savedCampaign = _id
        ? await campaignApi.updateCampaign(
          _id,
          { lists, listDetails, stepIdx: 0 },
          {
            activeDivisionId: storage.activeDivisionId,
          }
        )
        : await campaignApi.createCampaign(
          { lists, listDetails, stepIdx: 0, paid: storage.paid },
          {
            activeDivisionId: storage.activeDivisionId,
          }
        );

      dispatch(
        buildCampaign({
          ...savedCampaign,
          modified: false,
          errors: {},
        })
      );
      return savedCampaign._id;
    } catch (err) {
      dispatch(
        buildCampaign({
          errors: err?.response?.data ?? errors,
        })
      );
      return false;
    }
  };
}

export function saveCampaignTemplate() {
  return async (dispatch, getState) => {
    const { campaign, storage, masterTemplate, masterTemplates } = getState();
    const { _id, templateId, errors, modified, stepIdx, adhoc } = campaign;
    // if (!adhoc && !modified && 1 <= stepIdx) {
    //   return true;
    // }

    const data = await dispatch(
      saveMasterTemplate({ status: "COMPLETED", adhoc: Boolean(adhoc) })
    );

    try {
      const updatedCampaign = await campaignApi.updateCampaign(
        _id,
        {
          templateId: adhoc ? data.masterTemplateId : templateId,
          adhoc,
          stepIdx: 1,
        },
        {
          activeDivisionId: storage.activeDivisionId,
        }
      );
      dispatch(
        buildCampaign({
          ...updatedCampaign,
          modified: false,
          errors: {},
        })
      );
      return true;
    } catch (err) {
      dispatch(
        buildCampaign({
          errors: err?.response?.data ?? errors,
        })
      );
      return false;
    }
  };
}
export function saveCampaignTargetFields() {
  return async (dispatch, getState) => {
    const { campaign, storage } = getState();
    const {
      _id,
      smsTargetFieldsSetup,
      customTargetFieldsSetup,
      emailSubjectTargetFieldsSetup,
      emailTargetFieldsSetup,
      liveVoiceTargetFieldsSetup,
      answeringVoiceTargetFieldsSetup,
      ejsTargetFieldsSetup,
      errors,
      modified,
      stepIdx,
    } = campaign;

    if (!modified && 2 <= stepIdx) {
      return true;
    }

    try {
      const updatedCampaign = await campaignApi.updateCampaign(
        _id,
        {
          smsTargetFieldsSetup,
          customTargetFieldsSetup,
          emailSubjectTargetFieldsSetup,
          emailTargetFieldsSetup,
          liveVoiceTargetFieldsSetup,
          answeringVoiceTargetFieldsSetup,
          ejsTargetFieldsSetup,
          stepIdx: 2,
        },
        {
          activeDivisionId: storage.activeDivisionId,
        }
      );
      dispatch(
        buildCampaign({
          ...updatedCampaign,
          modified: false,
          errors: {},
        })
      );
      return true;
    } catch (err) {
      dispatch(
        buildCampaign({
          errors: err?.response?.data ?? errors,
        })
      );
      return false;
    }
  };
}

export function saveCampaignSettings() {
  return async (dispatch, getState) => {
    const { campaign, storage } = getState();
    const {
      _id,
      schedule,
      name,
      permission,
      smsOptover,
      confirmServiceAddress,
      errors,
      modified,
      stepIdx,
      bypassSuppression,
      bypassDndDns,
      isHighPriority,
      tags,
      sendAsApproved,
    } = campaign;

    if (!modified && 3 <= stepIdx) {
      return true;
    }

    try {
      const updatedCampaign = await campaignApi.updateCampaign(
        _id,
        {
          stepIdx: 3,
          schedule,
          name,
          permission,
          smsOptover,
          confirmServiceAddress,
          bypassSuppression,
          bypassDndDns,
          isHighPriority,
          tags,
          sendAsApproved,
        },
        {
          activeDivisionId: storage.activeDivisionId,
        }
      );
      dispatch(
        buildCampaign({
          ...updatedCampaign,
          modified: false,
          errors: {},
        })
      );
      return true;
    } catch (err) {
      dispatch(
        buildCampaign({
          errors: err?.response?.data ?? errors,
        })
      );
      return false;
    }
  };
}

export function saveCampaignApprovers() {
  return async (dispatch, getState) => {
    const { campaign, storage } = getState();
    const { _id, approvers, errors, modified, stepIdx } = campaign;

    if (!modified && 4 <= stepIdx) {
      return true;
    }

    try {
      const updatedCampaign = await campaignApi.updateCampaign(
        _id,
        { stepIdx: 4, approvers },
        {
          activeDivisionId: storage.activeDivisionId,
        }
      );
      dispatch(
        buildCampaign({
          ...updatedCampaign,
          modified: false,
          errors: {},
        })
      );
      return true;
    } catch (err) {
      dispatch(
        buildCampaign({
          errors: err?.response?.data ?? errors,
        })
      );
      return false;
    }
  };
}

export function sendCampaignTest(
  addresses,
  { emailTemplate, smsTemplate, voiceTemplate, ttyTemplate } = {}
) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId, paid } = storage;
    const filteredAddress = addresses.filter((address) => {
      if (
        (address.type == "email" && emailTemplate) ||
        (address.type == "sms" && smsTemplate) ||
        (address.type == "voice" && voiceTemplate) ||
        (address.type == "tty" && ttyTemplate)
      ) {
        return address;
      }
    });
    const validatedAddresses = await Promise.all(
      filteredAddress.map(async ({ type, value }) => {
        const testMessage =
          type === "email"
            ? {
              from: emailTemplate.fromEmail,
              to: value,
              testMessage: formatEscape(emailTemplate.template),
              subject: emailTemplate.subjectEmail,
              friendlyName: emailTemplate?.friendlyName,
            }
            : type === "sms"
              ? {
                from: String(smsTemplate.shortCode),
                to: value,
                testMessage: smsTemplate.template,
              }
              : type === "voice"
                ? {
                  to: value,
                  from: voiceTemplate.callerId,
                  testMessage: voiceTemplate.advanceVoice
                    ? []
                    : voiceTemplate.freeswitch.dialPlan.person.steps,
                  templateId: voiceTemplate._id,
                }
                : type === "tty"
                  ? {
                    to: value,
                    testMessage: ttyTemplate.tty,
                    templateId: ttyTemplate._id,
                  }
                  : {};

        try {
          await templateApi.sendTestMessage(
            { channelType: type, data: testMessage },
            { activeDivisionId, paid }
          );
          return { type, value };
        } catch (err) {
          return { type, value, error: true };
        }
      })
    );

    if (validatedAddresses.every((address) => address.error)) {
      dispatch(
        notify({
          type: "error",
          message: i18n.t("Failed to send test messages"),
        })
      );
    } else if (validatedAddresses.some((address) => address.error)) {
      dispatch(
        notify({
          type: "warning",
          message: i18n.t("Failed to send some test messages"),
        })
      );
    } else {
      dispatch(
        notify({
          type: "success",
          message: i18n.t("Successfully sent all test messages"),
        })
      );
    }

    return validatedAddresses;
  };
}

export function launchCampaign(campaignId, comment) {
  return async (dispatch, getState) => {
    const { storage } = getState();
    const { activeDivisionId } = storage;
    await campaignApi.launchCampaign({ activeDivisionId }, campaignId, comment);
    const campaign = await campaignApi.getCampaign(campaignId, {
      activeDivisionId,
    });
    dispatch(setCampaign(campaign));
    dispatch(
      notify({
        type: "success",
        message: i18n.t("Successfully overridden campaign launch"),
      })
    );
  };
}

export function buildCampaign(campaignData, { overwrite } = {}) {
  campaignData = { modified: !overwrite, ...campaignData };
  return { type: BUILD_CAMPAIGN, campaignData, overwrite };
}

export function resetCampaign(campaign) {
  const { name, email, sms } = campaign;
  const resetedCampaign = { email, sms, name };
  return { type: SET_CAMPAIGN, resetedCampaign };
}

export function setCampaign(newCampaign) {
  return { type: SET_CAMPAIGN, newCampaign };
}

export function clearCampaign() {
  return { type: CLEAR_CAMPAIGN };
}
