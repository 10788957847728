import React, { memo, useEffect, useState } from "react";
import { Buffer } from "buffer";
import { DropZone } from "../../../../components";
import { makeStyles } from "@material-ui/core/styles";
import { createFilePath } from "../../../Campaigns/Single/VoicePreview";
import { Grid, IconButton, MenuItem, TextField } from "../../../../components";
import { TTS_DATA_TYPE } from "../constants";
import { useDispatch } from "react-redux";
import { getAudioForTTs } from "../../../../actions/masterTemplate";

const useStyles = makeStyles((theme) => ({
  fileSelector: {
    width: "100%",
    height: 120,
  },
}));
function VoiceTTSInput({
  idx,
  voiceItem,
  errors,
  activeTab,
  onSetTTSData,
  readOnlyProps,
  testSection,
  selectedTtsVoice,
  readOnly,
}) {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [audioContent, setAudioContent] = useState("");

  const voiceItemsWithTTSAudio = async () => {
    if (!voiceItem?.transcript) {
      return;
    }
    const getAudioPayload = {
      transcript: voiceItem.transcript,
      voice: selectedTtsVoice,
    };
    const data = await dispatch(getAudioForTTs(getAudioPayload));
    const blob = new Blob([Buffer.from(data?.audioContent?.data, "base64")], {
      type: "audio/mp3",
    });
    setAudioContent(URL.createObjectURL(blob));
  };

  useEffect(() => {
    voiceItemsWithTTSAudio();
  }, [selectedTtsVoice]);

  return (
    <>
      <TextField
        multiline
        rows={4}
        rowsMax={8}
        variant="outlined"
        disabled={readOnly}
        fullWidth
        value={voiceItem.transcript ? voiceItem.transcript : " "}
        onChange={(e) => {
          onSetTTSData(idx, e.target.value);
        }}
        error={Boolean(
          errors[
            `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.transcript`
          ]
        )}
        helperText={
          errors[
            `voiceTemplate.freeswitch.dialPlan.${activeTab}.steps.${idx}.transcript`
          ]
        }
        onFocus={() => setAudioContent("")}
        onBlur={() => voiceItemsWithTTSAudio()}
        InputProps={readOnlyProps}
        inputProps={{
          "data-test-id": `master-template-voice-ttsData-goto-${TTS_DATA_TYPE}-${activeTab}-${testSection}-${idx}`,
        }}
      />
      {audioContent ? (
        <audio
          src={audioContent}
          controls
          className="my-2"
          style={{ height: "30px", width: "100%" }}
        >
          <source src={audioContent} type="audio/mpeg" />
          Your browser does not support the HTML audio
        </audio>
      ) : (
        <></>
      )}
    </>
  );
}
export default memo(VoiceTTSInput);
